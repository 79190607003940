import {useCallback} from 'react'
import {useConfigStore} from '@mosaic-wellness/redux-action-library'

import {EVENT_MAP} from 'src/analytics/eventMap'

function useCommsHomepageTracking() {
  const {analytics} = useConfigStore()

  const onCommsSectionViewed = useCallback(
    (data) => {
      analytics.trigger(EVENT_MAP.COMMS_CONSULT_SECTION_VIEWED, data)
    },
    [analytics]
  )

  const onCommsConsultStateCard = useCallback(
    (data) => {
      analytics.trigger(EVENT_MAP.COMMS_CONSULT_STATE_CARD, data)
    },
    [analytics]
  )

  const onCommsCardCTAClicked = useCallback(
    (data) => {
      analytics.trigger(EVENT_MAP.COMMS_CONSULT_CARD_CTA_CLICKED, data)
    },
    [analytics]
  )

  const onCommsEditApptClicked = useCallback(
    (data) => {
      analytics.trigger(EVENT_MAP.COMMS_EDIT_APPT_CLICKED, data)
    },
    [analytics]
  )

  const onCommsEditApptActionClicked = useCallback(
    (data) => {
      analytics.trigger(EVENT_MAP.COMMS_EDIT_APPT_ACTION_CLICKED, data)
    },
    [analytics]
  )

  const onCommsRescheduleConfirmed = useCallback(
    (data) => {
      analytics.trigger(EVENT_MAP.COMMS_RESCHEDULE_CONFIRMED, data)
    },
    [analytics]
  )

  const onCommsDAClicked = useCallback(
    (data) => {
      analytics.trigger(EVENT_MAP.COMMS_DA_CLICKED, data)
    },
    [analytics]
  )

  const onCommsFormClicked = useCallback(
    (data) => {
      analytics.trigger(EVENT_MAP.COMMS_FORM_CLICKED, data)
    },
    [analytics]
  )

  const onCommsConsultTrackerClicked = useCallback(
    (data) => {
      analytics.trigger(EVENT_MAP.COMMS_CONSULT_TRACKER_CLICKED, data)
    },
    [analytics]
  )

  const onCommsPastPrescriptionsClicked = useCallback(
    (data) => {
      analytics.trigger(EVENT_MAP.COMMS_PAST_PRESCRIPTIONS_CLICKED, data)
    },
    [analytics]
  )

  const onCommsPopUpViewed = useCallback(
    (data) => {
      analytics.trigger(EVENT_MAP.COMMS_POP_UP_VIEWED, data)
    },
    [analytics]
  )

  const onCommsPopUpCTAClicked = useCallback(
    (data) => {
      analytics.trigger(EVENT_MAP.COMMS_POP_UP_CTA_CLICKED, data)
    },
    [analytics]
  )

  const onCommsPopUpClosed = useCallback(
    (data) => {
      analytics.trigger(EVENT_MAP.COMMS_POP_UP_CLOSED, data)
    },
    [analytics]
  )
  return {
    onCommsSectionViewed,
    onCommsConsultStateCard,
    onCommsCardCTAClicked,
    onCommsEditApptClicked,
    onCommsEditApptActionClicked,
    onCommsRescheduleConfirmed,
    onCommsDAClicked,
    onCommsFormClicked,
    onCommsConsultTrackerClicked,
    onCommsPastPrescriptionsClicked,
    onCommsPopUpViewed,
    onCommsPopUpCTAClicked,
    onCommsPopUpClosed,
  }
}

export default useCommsHomepageTracking
