import styled from 'styled-components'

export const StyledModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  width: 100vw;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  &[data-is-floating-modal='true'] {
    align-items: center;
    justify-content: center;
  }

  z-index: 100;

  .payment-failed-modal {
    padding: 0;
  }

  .search-modal {
    padding: 0;
    margin: 0;
  }

  .search-modal-mobile {
    padding: 0;
    margin: 0;
    height: 100%;
    width: 100%;
    border-radius: 0;
    overflow: hidden;
  }

  &.search-modal-mobile-container {
    background-color: var(--brand-primary-white-color);
  }

  @media (min-width: 768px) {
    height: 100vh;
    align-items: center;
    align-content: center;
  }
`

export const StyledContent = styled.div`
  position: relative;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 14px 14px 0 0;
  width: 100vw;
  padding: ${({isPadding}) => (isPadding ? '20px' : '0px')};
  opacity: 1;
  &[data-is-floating-modal='true'] {
    margin: 0 16px;
    border-radius: 16px;
  }

  @media (min-width: 768px) {
    width: auto;
    margin: 200px;
    padding: 0px 40px;
    border-radius: 30px;
  }
`

export const StyledTitle = styled.div`
  font-family: var(--brand-font-family-title);
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 20px;
  margin-top: 30px;

  @media (min-width: 768px) {
    font-weight: normal;
    font-size: 60px;
    line-height: 50px;
    margin-top: 80px;
  }
`
export const CloseButton = styled.button`
  position: absolute;
  top: -48px;
  transform: translateX(-50%);
  left: 50%;
  right: 0;
  background-color: transparent;
  padding: 8px;
  border: none;
  width: 48px;
  height: 48px;
  cursor: pointer;

  img {
    width: 32px;
    height: 32px;
  }
  @media (min-width: 768px) {
    top: -58px;
    left: unset;
    transform: translateX(10%);
    right: 0;
    height: 50px;
    width: 50px;
    padding: 8px;
    img {
      width: 50px;
      height: 50px;
    }
  }
`
