export enum CTA_ACTION {
  ADD_TO_CART = 'ADD_TO_CART',
  OPEN_PDP = 'OPEN_PDP',
  APP_NAV = 'APP_NAV',
  TRIGGER_EVENTS_FOR_WEB = 'TRIGGER_EVENTS_FOR_WEB',
  UPDATE_DATA_LAYER = 'UPDATE_DATA_LAYER',
  SPA_LINK = 'SPA_LINK',
  BOTTOM_TAB = 'BOTTOM_TAB',
  CLEAR_APPOINTMENT_DETAILS_CONTEXT = 'CLEAR_APPOINTMENT_DETAILS_CONTEXT',
  GO_BACK = 'NAVIGATE_BACK',
  SHARE = 'SHARE',
  /**
   * @description currently available only for app's webviews
   * the functionality this is intended to is not implemented on web
   */
  AUTHORIZED_APP_NAV = 'AUTHORIZED_APP_NAV',
}

export interface IUseCta {
  action?: string
  data?: any
}
