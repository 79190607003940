import {IColor} from '../types'

export const Color: IColor = {
  PRIMARY: {
    500: '#22548A',
    400: '#4E76A1',
    300: '#7A98B9',
    200: '#A7BBD0',
    100: '#EBF1F9',
  },
  SECONDARY: {
    500: '#F2F2E8',
    400: '#F5F5ED',
    300: '#F7F7F1',
    200: '#FAFAF6',
    100: '#FCFCFA',
  },
  HAIR: {
    500: '#4A90DA',
    400: '#6EA6E1',
    300: '#92BCE9',
    200: '#B7D3F0',
    100: '#DBE9F8',
  },
  SKIN: {
    500: '#EC9556',
    400: '#F0AA78',
    300: '#F4BF9A',
    200: '#F7D5BB',
    100: '#FBEADD',
  },
  BEARD: {
    500: '#967A75',
    400: '#AB9591',
    300: '#C0AFAC',
    200: '#D5CAC8',
    100: '#EAE4E3',
  },
  NUTRITION: {
    500: '#5BB47F',
    400: '#7CC399',
    300: '#9DD2B2',
    200: '#BDE1CC',
    100: '#DEF0E5',
  },
  PERFORMANCE: {
    500: '#D47798',
    400: '#DD92AD',
    300: '#E5ADC1',
    200: '#EEC9D6',
    100: '#F6E4EA',
  },
  BODY: {
    500: '#56A6B8',
    400: '#78B8C6',
    300: '#9ACAD4',
    200: '#BBDBE3',
    100: '#DDEDF1',
  },
  WEIGHT: {
    500: '#56A6B8',
    400: '#78B8C6',
    300: '#9ACAD4',
    200: '#BBDBE3',
    100: '#DDEDF1',
  },
  SLEEP: {
    500: '#8275D0',
    400: '#9B91D9',
    300: '#B4ACE3',
    200: '#CDC8EC',
    100: '#E6E3F6',
  },
  SUCCESS: {
    500: '#379F8E',
    400: '#5FB2A5',
    300: '#87C5BB',
    200: '#AFD9D2',
    100: '#D7ECE8',
  },
  WARNING: {
    500: '#FF9800',
    400: '#FFAD33',
    300: '#FFC166',
    200: '#FFD699',
    100: '#FFF6E9',
  },
  ERROR: {
    500: '#DF555B',
    400: '#E5777C',
    300: '#EC999D',
    200: '#F2BBBD',
    100: '#F9DDDE',
  },
  PILLS: {
    BACKGROUND_SELECTED: '#22548A',
    BACKGROUND_UNSELECTED: '#EBF1F9',
    BACKGROUND_DISABLED: '#E3E3E3',
    TEXT_ICON_SELECTED: '#FFFFFF',
    TEXT_ICON_UNSELECTED: '#22548A',
    TEXT_ICON_DISABLED: '#9E9E9E',
  },
  BACKGROUND: {
    500: '#FFFFFF',
    400: '#FFFFFF',
    300: '#EC999D',
    200: '#F2BBBD',
    100: '#F9DDDE',
  },
  TEXT: {
    BRAND_TEXT: '#22548A',
    PRIMARY_TEXT: '#212121',
    SECONDARY_TEXT: '#666666',
    DISABLED_TEXT: '#9E9E9E',
  },
  NEUTRALS: {
    BLACK: '#000000',
    WHITE: '#FFFFFF',
    TEXT_FIELD_STROKE: '#C4C4C4',
  },
  DIVIDERS: {
    DARK: '#2E2E2E',
    LIGHT: '#E3E3E3',
  },
  RATING_STAR: {
    LIGHT: '#F6CA4C',
    DARK: '#F6CA4C',
  },
  TAG: {
    HIGHLIGHTED_TAG_BACKGROUND: '#FFEC6B',
    HIGHLIGHTED_TAG_TEXT: '#121212',
    DISCOUNT_TAG_BACKGROUND: '#FFEC6B',
    DISCOUNT_TAG_TEXT: '#379F8E',
    DEFAULT_TAG_BACKGROUND: '#F2F2E8',
    DEFAULT_TAG_TEXT: '#22548A',
  },
}
