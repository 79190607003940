import isEmpty from 'lodash/isEmpty'
import {
  useAuth,
  useCartStore,
  useCheckoutConfig,
  useConfigStore,
  useGetPrescription,
  useLoadingStore,
  useUserStore,
} from '@mosaic-wellness/redux-action-library'
import {useState, useCallback, useMemo, useRef, useEffect} from 'react'
import {useCheckoutCartPage} from '../checkoutV2/useCheckoutCart'
import {useFetchProduct} from '../product/useFetchProduct'
import {useGenericCta} from '../useGenericCta'
import {EVENT_MAP} from 'src/analytics/eventMap'
import {useExplicitATCTracking} from '../tracking/useExplicitATCTracking'
import {useRxCartTracking} from '../tracking/useRxCartTracking'
import {useGetAvailableCoupons} from '../checkout/getAvailableCoupons'
import {useUpdateItemToCart} from '../updateCart/useUpdateItemToCart'
import sessionStorageMethods from 'src/utils/sessionStorage/sessionStorageMethods'
import {analyticsTrigger} from 'src/analytics'

function useRxCartData() {
  const [{prescriptionData, isLoading, isError}, {getPrescription}] =
    useGetPrescription()
  const {cart} = useCartStore()

  const {addToCart, removeFromCart, updateItemToCart} = useUpdateItemToCart()

  const {
    cartLoading: {
      isCartApplyDiscount,
      isCartUpdating,
      cartInitialHydrationDone,
    },
  } = useLoadingStore()
  const {analytics} = useConfigStore()
  const CHECKOUT_CONFIG = useCheckoutConfig()
  const {
    state: {productData, isLoading: isProductLoading},
    actions: {fetchPdpData},
  } = useFetchProduct()
  const {handleCta} = useGenericCta()
  const {trackAddToCart} = useExplicitATCTracking()
  const [checkoutCartState, checkoutCartActions] = useCheckoutCartPage()
  const {
    user: {isLoggedIn},
  } = useUserStore()
  const {
    actions: {setShowLoginModal},
  } = useAuth()
  const {
    trackRxCartViewed,
    trackCheckoutClicked,
    trackProductInfoViewed,
    trackPdpViewedFromRxCart,
  } = useRxCartTracking()

  const {couponDataRes = {}, getData} = useGetAvailableCoupons()

  const initialLoadingDone = useRef(false)
  const checkoutIntentShownByUser = useRef(false)

  const [currentProductInPreview, setCurrentProductInPreview] = useState(null)
  const [removedProducts, setRemovedProducts] = useState<Array<any>>([])
  const [showProductPreviewModal, setShowProductPreviewModal] = useState(false)
  const [showModal, setShowModal] = useState(false)

  const {
    handleApplyDiscountOpen,
    onProductCardClick,
    onAddToCart,
    handleRewardUnlocked,
    handleCouponDiscount,
    handleIncentiveButtonClicked,
  } = checkoutCartActions
  const {
    totalAmount,
    savedAmount,
    recommended: recommendedProducts,
    products,
  } = checkoutCartState.cartState || {}

  const {cartConfig = {}} = CHECKOUT_CONFIG || {}
  const {availableCoupons, offerText = ''} = cartConfig
  const {
    productInfo = {},
    prod_img = '',
    card_for_with = {
      For: '',
    },
    howToUse = {},
    concern = '',
  } = productData

  const {upSellData, showRecommendations} = useMemo(() => {
    let recommendationsData = {}
    const recommendationsExist =
      Array.isArray(recommendedProducts) && !isEmpty(recommendedProducts)
    if (cartConfig.productRecommendations) {
      recommendationsData = {...cartConfig.productRecommendations}
    }

    if (recommendedProducts) {
      recommendationsData.products = recommendedProducts
    }

    return {
      upSellData: recommendationsData,
      showRecommendations: recommendationsExist,
    }
  }, [cartConfig, recommendedProducts])

  const pdpData = useMemo(() => {
    return {
      ...productInfo,
      productImageUrl: prod_img,
      howToUse,
      productFor: card_for_with?.For,
      concern,
    }
  }, [card_for_with?.For, concern, howToUse, prod_img, productInfo])

  const isCartLoading = useMemo(() => {
    let isLoading = !cartInitialHydrationDone
    const cartUpdating = isCartUpdating.status

    if (!isLoading && !cartUpdating) {
      initialLoadingDone.current = true
      return isLoading
    }

    if ((isLoading || cartUpdating) && !initialLoadingDone.current) {
      isLoading = cartUpdating || isLoading
    }

    return isLoading
  }, [cartInitialHydrationDone, isCartUpdating.status])

  const handleLogin = useCallback(() => {
    checkoutIntentShownByUser.current = true

    setShowLoginModal({
      isOpen: true,
      triggeredFrom: 'CHECKOUT_FLOW',
    })
  }, [])

  const handleModalClose = useCallback(() => {
    setShowModal(false)
  }, [])

  const handleModalOpen = useCallback(() => {
    setShowModal(true)
  }, [])

  const addRemovedProducts = useCallback((data) => {
    setRemovedProducts((removedProducts) => [...removedProducts, data])
  }, [])

  const deleteRemovedProducts = useCallback(
    (data) => {
      setRemovedProducts((removedProducts) =>
        removedProducts.filter((item) => item.id !== data.id)
      )
    },
    [removedProducts]
  )

  const handleAddToCart = useCallback(
    (data) => {
      const {sku = ''} = data
      trackAddToCart(data, window.location.href)
      addToCart({sku, isMiniCart: false})
    },
    [trackAddToCart, addToCart]
  )

  const onRemoveFromCart = useCallback(
    (id, sku, quantity) => {
      removeFromCart({
        sku,
        quantity,
        isMiniCart: false,
      })
    },
    [removeFromCart]
  )

  const onUpdateCartItem = useCallback(
    (id, sku, quantity) => {
      updateItemToCart({
        sku,
        isMiniCart: false,
        quantity,
      })
    },
    [updateItemToCart]
  )

  const trackApplyDiscountClick = useCallback(() => {
    analytics.trigger(EVENT_MAP.APPLY_DISCOUNT_CLICKED)
  }, [analytics])

  const handlePlaceOrder = useCallback(() => {
    trackCheckoutClicked({products, totalAmount})
    if (!isLoggedIn) {
      handleLogin()
      return
    }

    const link = `/checkout-v2?step=address`
    handleCta({action: 'LINK', link})
  }, [
    trackCheckoutClicked,
    products,
    totalAmount,
    isLoggedIn,
    handleCta,
    handleLogin,
  ])

  const onProductNameOrImageClick = useCallback(
    ({productURL, name}) => {
      trackProductInfoViewed({id: productURL, name})
      setShowProductPreviewModal(true)
      setCurrentProductInPreview(productURL)
    },
    [trackProductInfoViewed]
  )

  const onAllDetailsClick = useCallback(
    ({link, name}) => {
      trackPdpViewedFromRxCart({id: link, name})
      handleCta({
        action: 'SPA_LINK',
        link: `/product/${link}`,
      })
    },
    [handleCta, trackPdpViewedFromRxCart]
  )

  const closeProductPreviewModal = useCallback(() => {
    setShowProductPreviewModal(false)
    setCurrentProductInPreview(null)
  }, [])

  const onWalletSubmit = useCallback(
    (walletSubmitData: ISubmitData | null = null) => {
      const submitDataValue = walletSubmitData
      const {amount, bonus, source = 'recharge'} = submitDataValue
      analyticsTrigger(EVENT_MAP.WALLET_PAY_CLICK, {
        amount,
        bonus,
        source,
      })
      sessionStorageMethods.set({
        keyName: 'walletPaymentDetails',
        value: {amount, bonus},
      })
      handleCta({action: 'SPA_LINK', link: '/wallet-payment'})
    },
    [handleCta]
  )

  const onRecharge = useCallback(
    (data = null) => {
      sessionStorageMethods.set({keyName: 'isWalletCoupon', value: true})
      onWalletSubmit({...data, source: 'rx-cart'})
    },
    [onWalletSubmit]
  )

  useEffect(() => {
    if (checkoutIntentShownByUser.current && isLoggedIn) {
      const link = `/checkout-v2?step=address`
      handleCta({action: 'LINK', link})
    }
  }, [isLoggedIn])

  useEffect(() => {
    if (cartInitialHydrationDone && !isCartLoading && !!totalAmount) {
      trackRxCartViewed({
        products,
        totalAmount,
      })
    }
  }, [cartInitialHydrationDone, isCartLoading, trackRxCartViewed, totalAmount])

  const rxCartProps = useMemo(() => {
    return {
      rxCartState: {
        prescriptionData,
        isLoading,
        cart,
        isCartApplyDiscount,
        CHECKOUT_CONFIG,
        isProductLoading,
        checkoutCartState: {...checkoutCartState, couponDataRes},
        checkoutCartActions,
        currentProductInPreview,
        removedProducts,
        showProductPreviewModal,
        showModal,
        totalAmount,
        savedAmount,
        availableCoupons,
        offerText,
        upSellData,
        showRecommendations,
        pdpData,
        isCartLoading,
        couponDataRes,
      },
      rxCartActions: {
        handleModalClose,
        handleModalOpen,
        addRemovedProducts,
        deleteRemovedProducts,
        handleAddToCart,
        trackApplyDiscountClick,
        handlePlaceOrder,
        onProductNameOrImageClick,
        onAllDetailsClick,
        closeProductPreviewModal,
        handleApplyDiscountOpen,
        onProductCardClick,
        onAddToCart,
        handleRewardUnlocked,
        fetchPdpData,
        updateItemToCart: onUpdateCartItem,
        removeFromCart: onRemoveFromCart,
        getPrescription,
        getData,
        handleCouponDiscount,
        handleIncentiveButtonClicked,
        onRecharge,
      },
    }
  }, [
    prescriptionData,
    getData,
    couponDataRes,
    isLoading,
    cart,
    onUpdateCartItem,
    onRemoveFromCart,
    getPrescription,
    isCartApplyDiscount,
    CHECKOUT_CONFIG,
    isProductLoading,
    fetchPdpData,
    checkoutCartState,
    checkoutCartActions,
    currentProductInPreview,
    removedProducts,
    showProductPreviewModal,
    showModal,
    handleApplyDiscountOpen,
    onProductCardClick,
    onAddToCart,
    handleRewardUnlocked,
    totalAmount,
    savedAmount,
    availableCoupons,
    offerText,
    upSellData,
    showRecommendations,
    pdpData,
    handleModalClose,
    handleModalOpen,
    addRemovedProducts,
    deleteRemovedProducts,
    handleAddToCart,
    trackApplyDiscountClick,
    handlePlaceOrder,
    onProductNameOrImageClick,
    onAllDetailsClick,
    closeProductPreviewModal,
    isCartLoading,
    handleCouponDiscount,
    handleIncentiveButtonClicked,
    onRecharge,
  ])

  return rxCartProps
}

export {useRxCartData}
