export const SESSION_STORAGE_CONSTANTS = {
  SHOWN_PAYMENT_MODAL: 'shownPaymentModal',
  SLOTS_DATA: 'slotsData',
  ELIGIBLE_PAY_METHODS: 'eligiblePayMethods',
  LAST_PAYMENT_METHOD: 'lastPaymentMethod',
  WALLET_PAYMENT_DETAILS: 'walletPaymentDetails',
  IS_WALLET_PAYMENT: 'isWalletPayment',
  IS_WALLET_COUPON: 'isWalletCoupon',
  WALLET_RECHARGE_SUCCESS_REDIRECTION_LINK:
    'walletRechargeSuccessRedirectionLink',
  BOOK_CONSULTATION_ERROR_REDIRECT: 'bookConsultationErrorRedirect',
  CONSULT_CLEAR_DUES_SUCCESS_REDIRECTION_LINK:
    'consultClearDuesSuccessRedirectionLink',
  NOTIFY_ME_COMPLETE_TITLE: 'notifyMeCompleteTitle',
  NOTIFY_ME_COMPLETE_SUB_TITLE: 'notifyMeCompleteSubTitle',
} as const
