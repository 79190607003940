export const logError = (
  error: Error,
  meta?: {
    location?: string
    source?: string
    parsedData?: string
  }
) => {
  // @ts-ignore
  if (typeof window && window.newrelic) {
    window.newrelic.noticeError(error, {...meta, custom: true})
  } else {
    console.error(error)
  }
}

export const logInfo = (
  message: string,
  meta?: {
    location?: string
    source?: string
  }
) => {
  console.info(message, meta)
}
