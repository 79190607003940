import useCheckPrescriptionV2 from '../checkPrescriptionV2/useCheckPrescriptionV2'
import {useGetAvailableCoupons} from '../checkout/getAvailableCoupons'
import {useCartUpSell} from '../tracking/useCartUpSell'
import {useExplicitATCTracking} from '../tracking/useExplicitATCTracking'
import {useUpdateItemToCart} from '../updateCart/useUpdateItemToCart'
import {CTA_ACTION, useGenericCta} from '../useGenericCta'
import {
  useAction,
  useCartStore,
  useConfigStore,
  useLoadingStore,
  useUserStore,
  useWalletPoints,
} from '@mosaic-wellness/redux-action-library'
import isEmpty from 'lodash/isEmpty'
import {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {analyticsTrigger} from 'src/analytics'
import {EVENT_MAP} from 'src/analytics/eventMap'
import {useCheckoutStore} from 'src/stores/checkout/useCheckout.store'
import {SESSION_STORAGE_CONSTANTS} from 'src/utils/sessionStorage/sessionStorageConstants'
import sessionStorageMethods from 'src/utils/sessionStorage/sessionStorageMethods'

interface IUseCheckoutCartPage {
  callCheckPrescription?: boolean
  refreshCartExpicitly?: boolean
  source?: string
}

export const useCheckoutCartPage = (props: IUseCheckoutCartPage = {}) => {
  const {
    callCheckPrescription = false,
    refreshCartExpicitly = false,
    source = 'Cart',
  } = props

  /* Custom Hooks */
  const {applyDiscount, cart, getItemQuantity, refreshCart} = useCartStore()
  const {addToCart, removeFromCart, updateItemToCart} = useUpdateItemToCart()
  const {
    cartLoading: {
      isCartApplyDiscount,
      isCartUpdating,
      cartInitialHydrationDone,
      isCartHydration,
    },
  } = useLoadingStore()
  const {
    state: {walletPoints},
  } = useWalletPoints()
  const {navigateTo} = useAction()
  const {handleUpSellSelection, handleUpSellShow} = useCartUpSell()
  const {
    user: {isLoggedIn},
  } = useUserStore()
  const [
    {
      checkPrescriptionData,
      isLoading: isLoadingCheckPrescriptionData,
      isInitialCheckPrescriptionCallMade,
    },
    {checkPrescription, resetCheckPrescriptionData},
  ] = useCheckPrescriptionV2()
  const {couponDataRes = {}, getData} = useGetAvailableCoupons()
  const isCartFetchedAfterNavigation = useCheckoutStore(
    (state: any) => state.isCartFetchedAfterNavigation
  )
  const setIsCartFetchedAfterNavigation = useCheckoutStore(
    (state: any) => state.setIsCartFetchedAfterNavigation
  )
  const {handleCta} = useGenericCta()
  const {trackAddToCart} = useExplicitATCTracking()
  const {analytics} = useConfigStore()
  const bookSlotsData = useCheckoutStore((state) => state.bookSlotsData)
  const resetSlotsData = useCheckoutStore((state) => state.resetSlotsData)
  const setBookSlotsData = useCheckoutStore((state) => state.setBookSlotsData)

  /* States */
  const [languageForCheckPrescription, setLanguageForCheckPrescription] =
    useState<string>('')
  const [isScheduleFormOpen, setIsScheduleFormOpen] = useState<boolean>(false)

  /* Refs */
  const cartInitialLoading = useRef(false)
  const cartFetched = useRef(false)

  /* Variables Destructuring */
  const {recommended, ...restOFCart} = cart
  const {rxStatus} = restOFCart || {}
  const {
    hasInstantRx = false,
    hasPseudoRx = false,
    hasRx = false,
    rxCategories,
  } = rxStatus || {}

  const shouldCallCheckPrescription: boolean = useMemo(() => {
    return (
      (!!hasRx || !!hasPseudoRx || !!hasInstantRx) &&
      isLoggedIn &&
      callCheckPrescription
    )
  }, [callCheckPrescription, hasInstantRx, hasPseudoRx, hasRx, isLoggedIn])

  const isCartLoading = useMemo(() => {
    let isLoading = !cartInitialHydrationDone || isCartHydration
    const cartUpdating = isCartUpdating.status

    if (!isLoading && !cartUpdating) {
      cartInitialLoading.current = true
      return isLoading
    }

    if ((isLoading || cartUpdating) && !cartInitialLoading.current) {
      isLoading = cartUpdating || isLoading
    }

    return isLoading
  }, [cartInitialHydrationDone, isCartUpdating.status, isCartHydration])

  const showCartAppointmentCardLoader = useMemo(() => {
    return (
      isLoadingCheckPrescriptionData &&
      !isInitialCheckPrescriptionCallMade.current
    )
  }, [isInitialCheckPrescriptionCallMade, isLoadingCheckPrescriptionData])

  const isAnyDiscountApplied = useMemo(() => {
    const discount = cart?.appliedDiscount?.discount

    return discount?.coupon?.isApplied || discount?.wallet?.isApplied
  }, [cart?.appliedDiscount?.discount])

  const prescriptionData = useMemo(() => {
    return {
      ...checkPrescriptionData?.prescriptionData?.prescription,
      ...checkPrescriptionData?.prescriptionData?.doctor,
      diagnosis: checkPrescriptionData?.prescriptionData?.diagnosis,
    }
  }, [checkPrescriptionData])

  const onProductCardClick = useCallback(
    (product, source) => {
      handleCta({
        action: CTA_ACTION.PRODUCT_NAVIGATION,
        product: {...product, urlKey: product.urlKey || product.productURL},
        source,
      })
    },
    [handleCta]
  )

  const handleWalletDiscount = useCallback(
    (type = 'apply') => {
      if (type === 'apply') {
        applyDiscount('wallet', true, `${walletPoints ? walletPoints : 0}`)
        return
      }

      return applyDiscount('wallet', false, '')
    },
    [applyDiscount, walletPoints]
  )

  const removeItemFromCart = useCallback(
    (productDetails: any, propertyName: String) => {
      const {item = {}, id = '', quantity = ''} = productDetails
      const {sku = ''} = item
      if (propertyName === 'N') {
        analyticsTrigger(EVENT_MAP.RX_POPUP_CLICKED, {propertyName})
        return
      } else if (propertyName === 'Y') {
        analyticsTrigger(EVENT_MAP.RX_POPUP_CLICKED, {propertyName})
      }
      if (productDetails && sku && id && quantity) {
        removeFromCart({
          sku,
          quantity: quantity,
          isMiniCart: false,
        })
      }
    },
    [removeFromCart]
  )

  const showCheckoutPrompt = useCallback((id) => {
    const eventObject = {
      productID: id,
    }
    analyticsTrigger(EVENT_MAP.RX_POPUP_SEEN, eventObject)
  }, [])

  const handleCouponDiscount = useCallback(
    (isApply: boolean, value: string) => {
      analyticsTrigger('couponApplyClick', {
        code: value,
      })
      applyDiscount('coupon', isApply, value)
    },
    [applyDiscount]
  )

  const handleIncentiveButtonClicked = useCallback(
    (cta: string, coupon: string) => {
      analyticsTrigger(EVENT_MAP.CART_UP_SELL_INCENTIVE_BUTTON_CLICKED, {
        cta,
        coupon,
      })
    },
    []
  )

  const handleAddToCart = useCallback(
    (product, source) => {
      const {sku} = product || {}
      trackAddToCart(product, source)
      addToCart({
        sku,
        isMiniCart: false,
      })
    },
    [addToCart, trackAddToCart]
  )

  const handleUpdateCartItem = useCallback(
    (id, sku, quantity) => {
      updateItemToCart({
        sku,
        quantity,
        isMiniCart: false,
      })
    },
    [updateItemToCart]
  )

  const handleApplyDiscountOpen = useCallback(() => {
    analyticsTrigger('applyDiscountClicked')
  }, [])

  const handleRewardUnlocked = useCallback((data) => {
    const {mileStone} = data
    analyticsTrigger(EVENT_MAP.REWARD_UNLOCKED, {mileStone})
  }, [])

  const onLanguageChangeForCheckPrescription = useCallback(
    (language: string) => {
      const rxCategories = Array.from(new Set(cart?.rxStatus?.rxCategories))

      setLanguageForCheckPrescription(language)
      checkPrescription({
        categories: Array.from(new Set(rxCategories)) || [],
        language,
      })
    },
    [cart?.rxStatus?.rxCategories, checkPrescription]
  )

  const toggleScheduleFormOpen = useCallback(
    () => setIsScheduleFormOpen((isOpen) => !isOpen),
    []
  )

  const onMilestoneUnlocked = useCallback((data) => {
    analyticsTrigger(EVENT_MAP.UPSELL_MILESTONE_UNLOCKED, data)
  }, [])

  const onUpsellViewAllClicked = useCallback(() => {
    analyticsTrigger(EVENT_MAP.UPSELL_VIEW_ALL_CLICKED)
  }, [])

  const onPseudoRxCancelClick = useCallback(() => {
    resetSlotsData()
  }, [resetSlotsData])

  const updateSlotsData = useCallback(
    (data: any, isInitialSlotBeingSet = false) => {
      if (isInitialSlotBeingSet) {
        let dataFromLocalStorage: null | Record<string, string> = null

        dataFromLocalStorage = sessionStorageMethods.get(
          SESSION_STORAGE_CONSTANTS.SLOTS_DATA
        )

        if (!isEmpty(dataFromLocalStorage)) {
          setBookSlotsData(dataFromLocalStorage)
          return
        }
      }

      setBookSlotsData(data)
      sessionStorageMethods.set({
        keyName: SESSION_STORAGE_CONSTANTS.SLOTS_DATA,
        value: {
          ...data,
        },
      })
    },
    [setBookSlotsData]
  )

  const onSaveChangesClicked = useCallback(
    (data: any) => {
      updateSlotsData(data)
      toggleScheduleFormOpen()
    },
    [toggleScheduleFormOpen, updateSlotsData]
  )

  const onAppointmentCardShown = useCallback(
    (data: any) => {
      analytics.trigger(EVENT_MAP.APPOINTMENT_CARD_SHOWN, {...data, source})
    },
    [analytics, source]
  )

  const onAppointmentCardClick = useCallback(
    (data: any) => {
      analytics.trigger(EVENT_MAP.APPOINTMENT_CARD_CLICK, {...data, source})
    },
    [analytics, source]
  )

  useEffect(() => {
    if (!cartFetched.current && refreshCartExpicitly) {
      cartFetched.current = true
      setIsCartFetchedAfterNavigation(true)
      refreshCart()
    }
    return () => setIsCartFetchedAfterNavigation(false)
  }, [refreshCart, refreshCartExpicitly, setIsCartFetchedAfterNavigation])

  // Call check-prescription if cart has rx or pseudoRx products or instant rx products
  useEffect(() => {
    if (shouldCallCheckPrescription) {
      checkPrescription({
        categories: Array.from(new Set(rxCategories)) || [],
        language: languageForCheckPrescription,
      })
    } else {
      resetCheckPrescriptionData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldCallCheckPrescription, rxCategories])

  /* Set default slot if flag true or available from outside (eg. Form Cart) */
  useEffect(() => {
    if (!shouldCallCheckPrescription) {
      return
    }

    if (checkPrescriptionData?.setDefaultSlot && isEmpty(bookSlotsData)) {
      const {cardData} = checkPrescriptionData || {}

      let slotData = {
        category: rxCategories,
        date: cardData?.appointmentDetails?.date,
        slot: cardData?.appointmentDetails?.time,
        immediate: !!cardData?.appointmentDetails?.immediate,
        language: cardData?.appointmentDetails?.language || '',
      }
      slotData = {
        ...slotData,
      }

      updateSlotsData(slotData, true)
    } else {
      resetSlotsData()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkPrescriptionData?.setDefaultSlot])

  /* clear slots and check prescription data when unmounting */

  useEffect(() => {
    return () => {
      resetSlotsData()
      resetCheckPrescriptionData()
      sessionStorageMethods.remove(SESSION_STORAGE_CONSTANTS.SLOTS_DATA)
    }
  }, [resetCheckPrescriptionData, resetSlotsData])

  const stateAndActions = useMemo(() => {
    return [
      {
        cartState: {
          ...restOFCart,
          recommended,
          isCartHydration,
          couponDataRes,
          cartInitialHydrationDone,
          isCartApplyDiscount,
          isCartLoading,
          isCartFetchedAfterNavigation,
          appliedDiscount: {
            discount: {
              ...restOFCart?.appliedDiscount?.discount,
              wallet: !!restOFCart.appliedDiscount?.discount?.wallet
                ? restOFCart.appliedDiscount?.discount?.wallet
                : undefined,
            },
          },
          isLoggedIn,
        },
        checkPrescriptionData,
        isAnyDiscountApplied,
        walletPoints,
        isLoadingCheckPrescriptionData,
        isScheduleFormOpen,
        isCartUpdating,
        prescriptionData,
        bookSlotsData,
        showCartAppointmentCardLoader,
        languageForCheckPrescription,
        isInitialCheckPrescriptionCallMade,
      },
      {
        getItemQuantity: getItemQuantity,
        onCartUpdateDiscount: {
          coupon: handleCouponDiscount,
          wallet: handleWalletDiscount,
        },
        handleCouponDiscount,
        getData,
        onAddToCart: handleAddToCart,
        onCartUpdateProduct: handleUpdateCartItem,
        onNavigation: navigateTo,
        onProductCardClick: onProductCardClick,
        onRemoveFromCart: removeItemFromCart,
        onPromptShow: showCheckoutPrompt,
        onUpSellSelection: handleUpSellSelection,
        onUpSellShow: handleUpSellShow,
        handleApplyDiscountOpen,
        triggerLogin: () => handleCta({action: 'AUTH'}),
        handleRewardUnlocked,
        refreshCart,
        handleIncentiveButtonClicked,
        onLanguageChangeForCheckPrescription,
        checkPrescription,
        toggleScheduleFormOpen,
        onMilestoneUnlocked,
        onUpsellViewAllClicked,
        onPseudoRxCancelClick,
        onSaveChangesClicked,
        onAppointmentCardShown,
        onAppointmentCardClick,
      },
    ] as const
  }, [
    restOFCart,
    recommended,
    isCartHydration,
    couponDataRes,
    cartInitialHydrationDone,
    isCartApplyDiscount,
    isCartLoading,
    isLoggedIn,
    checkPrescriptionData,
    isAnyDiscountApplied,
    walletPoints,
    isLoadingCheckPrescriptionData,
    isScheduleFormOpen,
    isCartUpdating,
    prescriptionData,
    bookSlotsData,
    showCartAppointmentCardLoader,
    getItemQuantity,
    handleCouponDiscount,
    handleWalletDiscount,
    getData,
    handleAddToCart,
    handleUpdateCartItem,
    navigateTo,
    onProductCardClick,
    removeItemFromCart,
    showCheckoutPrompt,
    handleUpSellSelection,
    handleUpSellShow,
    handleApplyDiscountOpen,
    handleRewardUnlocked,
    refreshCart,
    handleIncentiveButtonClicked,
    onLanguageChangeForCheckPrescription,
    checkPrescription,
    toggleScheduleFormOpen,
    onMilestoneUnlocked,
    onUpsellViewAllClicked,
    onPseudoRxCancelClick,
    onSaveChangesClicked,
    onAppointmentCardShown,
    onAppointmentCardClick,
    handleCta,
    languageForCheckPrescription,
    isInitialCheckPrescriptionCallMade,
    isCartFetchedAfterNavigation,
  ])

  return stateAndActions
}
