import {getStoreInstance} from '@mosaic-wellness/redux-action-library'
import {create} from 'zustand'

const BRAND = process.env.NEXT_PUBLIC_BRAND || 'mm'

export interface IBookSlotData {
  date?: string
  slot?: string
  mode?: string
  brand?: string
  phone?: string
  email?: string
  user_id?: string
  patient_name?: string
  category?: string[]
  slot_time_period?: number
  source?: string
  immediate?: boolean
  language?: string
  doctor_id?: string
}
type IUpdateSlotsParams = Pick<
  IBookSlotData,
  'date' | 'slot' | 'immediate' | 'category' | 'language'
>

interface ICheckoutStore {
  isTabbyLoaded: boolean
  bookSlotsData: IBookSlotData | null
  isCartFetchedAfterNavigation: boolean
  onTabbyLoad: () => void
  setBookSlotsData: (params: IUpdateSlotsParams) => void
  resetSlotsData: () => void
  setIsCartFetchedAfterNavigation: (flag: boolean) => void
}

export const useCheckoutStore = create<ICheckoutStore>((set) => ({
  isTabbyLoaded: false,
  bookSlotsData: null,
  isCartFetchedAfterNavigation: false,
  onTabbyLoad: () => set(() => ({isTabbyLoaded: true})),
  setBookSlotsData: (params: IUpdateSlotsParams) =>
    set(() => {
      const {firstName, email, id, lastName, phoneNumber} =
        getStoreInstance()?.getState()?.user || {}

      return {
        bookSlotsData: {
          ...params,
          doctor_id: '',
          mode: 'bw_call',
          brand: BRAND.toUpperCase(),
          phoneNumber,
          email,
          user_id: id,
          patient_name: firstName + lastName,
          category: Array.from(new Set(params.category)),
          slot_time_period: 10,
          source: 'O',
          source_id: id,
          payment_method: '',
        },
      }
    }),
  resetSlotsData: () => set(() => ({bookSlotsData: null})),
  setIsCartFetchedAfterNavigation: (flag: boolean) =>
    set(() => ({isCartFetchedAfterNavigation: flag})),
}))
