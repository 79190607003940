import {useConfigStore} from '@mosaic-wellness/redux-action-library'
import {useCallback} from 'react'
import {useProductClickTracking} from 'src/hooks/tracking/useProductClick'
import {useProductNavigation} from './useProductNavigation'
interface ISwitchItem {
  slugUrl?: string
  urlKey: string
  id: string
  name: string
}

export const useProductSwitchActions = () => {
  const {analytics} = useConfigStore()
  const {trackProductCardClick} = useProductClickTracking()
  const {navigateToProduct} = useProductNavigation()

  const handleSwitch = useCallback(
    (switchItem: ISwitchItem, switchSource: string = '') => {
      const {slugUrl = '', urlKey = ''} = switchItem || {}
      if (switchSource) {
        trackProductCardClick({...switchItem, source: switchSource})
      }
      analytics.trigger('pdSwitchClick', {
        ...switchItem,
      })
      navigateToProduct({slugUrl, urlKey})
    },
    [analytics, trackProductCardClick, navigateToProduct]
  )

  return {
    onSwitch: handleSwitch,
  }
}
