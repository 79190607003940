import {Size} from '../size'
import {Color as BWColor} from '../BW/colors'
import {LineHeight as BWLineHeight} from '../BW/lineHeight'
import {fontFamily as BWFontFamily} from '../BW/font'
import {Color as LJColor} from '../LJ/colors'
import {LineHeight as LJLineHeight} from '../LJ/lineHeight'
import {fontFamily as LJFontFamily} from '../LJ/font'
import {Color as LJAEColor} from '../LJ-AE/colors'
import {LineHeight as LJAELineHeight} from '../LJ-AE/lineHeight'
import {fontFamily as LJAEFontFamily} from '../LJ-AE/font'
import {Color as LJSAColor} from '../LJ-SA/colors'
import {LineHeight as LJSALineHeight} from '../LJ-SA/lineHeight'
import {fontFamily as LJSAFontFamily} from '../LJ-SA/font'
import {Color as MMColor} from '../MM/colors'
import {LineHeight as MMLineHeight} from '../MM/lineHeight'
import {fontFamily as MMFontFamily} from '../MM/font'
import {IColor} from '../types'

type IBrand = 'lj' | 'lj-ae' | 'lj-sa' | 'bw' | 'mm'

const color: Record<IBrand, IColor> = {
  lj: LJColor,
  'lj-ae': LJAEColor,
  'lj-sa': LJSAColor,
  bw: BWColor,
  mm: MMColor,
}

const lineHeight: Record<IBrand, string> = {
  lj: LJLineHeight,
  'lj-ae': LJAELineHeight,
  'lj-sa': LJSALineHeight,
  bw: BWLineHeight,
  mm: MMLineHeight,
}

const fontFamily: Record<IBrand, string> = {
  lj: LJFontFamily,
  'lj-ae': LJAEFontFamily,
  'lj-sa': LJSAFontFamily,
  bw: BWFontFamily,
  mm: MMFontFamily,
}

interface ITheme {
  color: IColor
  lineHeight: string
  sizes: typeof Size
  fontFamily: string
}

export const generateTheme = (brand: IBrand): ITheme => {
  return {
    color: color[brand],
    lineHeight: lineHeight[brand],
    sizes: Size,
    fontFamily: fontFamily[brand],
  }
}
