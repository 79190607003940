import {useCallback} from 'react'

import {useConfigStore} from '@mosaic-wellness/redux-action-library'
import {EVENT_MAP} from 'src/analytics/eventMap'

function useConfirmationScreenV2Tracking() {
  const {analytics} = useConfigStore()

  const trackConfirmationScreenV2 = useCallback(
    ({type = '', data}) => {
      switch (type) {
        case 'screenViewed': {
          analytics.trigger(EVENT_MAP.CONFIRMATION_SCREEN_V2_VIEWED, data)
          break
        }

        case 'downloadClicked': {
          analytics.trigger(
            EVENT_MAP.CONFIRMATION_SCREEN_V2_DOWNLOAD_APP_CLICKED,
            data
          )
          break
        }

        case 'edit': {
          analytics.trigger(
            EVENT_MAP.CONFIRMATION_SCREEN_V2_EDIT_APPOINTMENT_CLICKED,
            data
          )
          break
        }

        case 'reschedule': {
          analytics.trigger(
            EVENT_MAP.CONFIRMATION_SCREEN_V2_RESCHEDULE_CLICKED,
            data
          )
          break
        }

        case 'cancel': {
          analytics.trigger(
            EVENT_MAP.CONFIRMATION_SCREEN_V2_APPOINTMENT_CANCELLED,
            data
          )
          break
        }

        default:
          break
      }
    },
    [analytics]
  )

  return {
    trackConfirmationScreenV2,
  }
}

export {useConfirmationScreenV2Tracking}
