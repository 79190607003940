import {
  HttpClient,
  useFloatingIslandNudgesData,
  useFormQuestions,
  useGetSlotsData,
  useUploadImagesV3,
} from '@mosaic-wellness/redux-action-library'
import {IUseChatBoxProps, UseChatBoxReturnType} from './useChatBox.interface'
import {checkIsApp} from 'src/utils/checkIsApp'
import {useInnerHeight} from '../useInnerHeight/useInnerHeight'
import {useCallback, useEffect, useState} from 'react'
import {IConsultConfig} from 'src/components/DirectAssessment/DirectAssessment.interface'

function useChatBox(props: IUseChatBoxProps): UseChatBoxReturnType {
  const {source = 'form', rawDetails, fetchDaConfig = false} = props || {}

  const [daConfig, setDaConfig] = useState<IConsultConfig | null>(null)

  const [state, actions] = useFormQuestions({
    source,
    rawDetails: {...rawDetails},
  })
  const {state: imageUploadState, actions: imageUploadActions} =
    useUploadImagesV3()
  const {state: getSlotsState, actions: getSlotsActions} = useGetSlotsData()
  const [{}, {getFloatingIslandNudgeData}] = useFloatingIslandNudgesData()

  const {innerHeight} = useInnerHeight()
  const isApp = checkIsApp()

  const getCameraPermission = useCallback(() => {
    if (typeof window !== 'undefined') {
      //@ts-expect-error
      window?.ReactNativeWebView?.postMessage(
        JSON.stringify({
          actions: 'REQUEST_CAMERA_PERMISSION',
        })
      )
    }
  }, [])

  useEffect(() => {
    if (fetchDaConfig) {
      ;(async function () {
        const axiosInstance = HttpClient.getAxiosInstance()
        const {data} = await axiosInstance.get('/page/da-config')

        if (data?.data) {
          setDaConfig(data?.data)
        }
      })()
    }
  }, [fetchDaConfig])

  return [
    {...state, imageUploadState, getSlotsState, isApp, innerHeight, daConfig},
    {
      ...actions,
      imageUploadActions,
      getSlotsActions,
      getCameraPermission,
      getFloatingIslandNudgeData,
    },
  ]
}

export {useChatBox}
