import {useConfigStore} from '@mosaic-wellness/redux-action-library'
import {useCallback} from 'react'

import {EVENT_MAP} from 'src/analytics/eventMap'
import {IUseTreatmentPage} from './useTreatmentPage.interface'

function useTreatmentPage(props: IUseTreatmentPage) {
  const {analytics} = useConfigStore()

  const {category = '', url = ''} = props || {}

  const onTreatmentPageViewed = useCallback(() => {
    analytics.trigger(EVENT_MAP.TREATMENT_PAGE_VIEWED, {
      url,
      category,
    })
  }, [analytics])

  const onCustomisedPlanClicked = useCallback(() => {
    analytics.trigger(EVENT_MAP.CUSTOMISED_PLAN_CLICKED, {
      url,
      category,
    })
  }, [analytics])

  const onStartTreatmentClicked = useCallback(
    (data) => {
      analytics.trigger(EVENT_MAP.START_TREATMENT_CLICKED, {
        product: data.product,
        url,
        category,
        sku: data.sku,
        amount: data.amount,
        month: data.month,
        custom: data.custom,
      })
    },
    [analytics]
  )

  const onNextClicked = useCallback(() => {
    analytics.trigger(EVENT_MAP.TREATMENT_QUESTIONNAIRE_NEXT_CLICKED, {
      url,
      category,
    })
  }, [analytics])

  const onFindSolutionClicked = useCallback(
    (data) => {
      analytics.trigger(EVENT_MAP.FIND_SOLUTION_CLICKED, {
        url,
        category,
        score: data.score,
      })
    },
    [analytics]
  )

  return {
    onTreatmentPageViewed,
    onCustomisedPlanClicked,
    onStartTreatmentClicked,
    onFindSolutionClicked,
    onNextClicked,
  }
}

export {useTreatmentPage}
