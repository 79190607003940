import {IDoctorCore} from 'src/Screens/DoctorList/types'
import {CTA_ACTION} from '../useGenericCta'

const getDoctorCtaLabel = (
  doctor: IDoctorCore,
  isLoggedIn: boolean,
  loginCtaText: string = 'Login to chat with doctor',
  viewProfileCtaText: string = 'View Profile'
): IDoctorCore => {
  if (!isLoggedIn) {
    return {
      ...doctor,
      footerCta: [
        {
          action: CTA_ACTION.AUTH,
          label: loginCtaText,
          triggeredFrom: 'SO_LOGIN',
          link: '',
        },
        {
          action: CTA_ACTION.APP_NAV,
          variant: 'secondary',
          label: viewProfileCtaText,
          link: `/doctor/${doctor.id}`,
        },
      ],
    }
  }

  return doctor
}

const getLoggedOutDoctors = (
  doctors: IDoctorCore[],
  isLoggedIn: boolean,
  loginCtaText: string = 'Login to chat with doctor',
  viewProfileCtaText: string = 'View Profile'
) => {
  return doctors.map((doctor) =>
    getDoctorCtaLabel(doctor, isLoggedIn, loginCtaText, viewProfileCtaText)
  )
}

export {getDoctorCtaLabel, getLoggedOutDoctors}
