import {useCallback, useEffect, useMemo, useState} from 'react'
import noop from 'lodash/noop'

import {useGenericCtaV2} from '..'
import {IOrderStatusV3} from '../../components/ThankYouV2/ThankYouV2.interface'
import {ILink} from 'src/components/shared/src/Screens/ThankYouPageV2/ExploreCarousel/ ExploreCarousel.interface'
import {
  useConfigStore,
  useGetAppRatingModalData,
} from '@mosaic-wellness/redux-action-library'
import {useBirthdayTracker} from '../birthdayTracker/useBirthdayTracker'
import {EVENT_MAP} from 'src/analytics/eventMap'
import {checkIsApp} from 'src/utils/checkIsApp'

interface IUseThankYouV2Props {
  sections: IOrderStatusV3 | undefined
}

type useThankYouV2ReturnType = [
  {
    showShippingChargesModal: boolean
    shippingChargesModalData: {title: string; subTitle: string}
    appRatingModalData: Record<string, any> | null
  },
  {
    onShippingChargesClicked: () => void
    onShippingInfoClick: () => void
    onCTAClick: (link: ILink, ctaData: {label: string; section: string}) => void
    onBirthdayTrackerSeen: () => void
    onBirthdayTrackerAddChildClicked: () => void
    onBirthdayTrackerCancelClicked: () => void
    saveKidsData: (data: any) => void
    handleCloseClick: () => void
  }
]

export const useThankYouV2 = (
  props: IUseThankYouV2Props
): useThankYouV2ReturnType => {
  const {sections} = props
  const {billingDetails} = sections || {}
  const {shippingCharges} = billingDetails || {}
  const [showShippingChargesModal, setShowShippingChagesModal] =
    useState<boolean>(false)

  const {analytics} = useConfigStore()
  const {handleCtaV2 = noop} = useGenericCtaV2()
  const isApp = checkIsApp()

  const [
    ,
    {
      onBirthdayTrackerSeen = noop,
      onBirthdayTrackerAddChildClicked = noop,
      onBirthdayTrackerCancelClicked = noop,
      saveKidsData = noop,
      handleCloseClick = noop,
    },
  ] = useBirthdayTracker()

  const [{appRatingModalData}, {getAppRatingModalData}] =
    useGetAppRatingModalData()

  const handleShippingInfoClicked = useCallback(() => {
    setShowShippingChagesModal(!showShippingChargesModal)
  }, [showShippingChargesModal])

  const shippingCharge = useMemo(() => {
    const {receiptV2} = billingDetails || {}
    const {analyticsInfo} = receiptV2 || {}
    const {shippingAmount = 0} = analyticsInfo || {}
    return shippingAmount
  }, [billingDetails])

  const analyticsData = useMemo(() => {
    return {
      orderSummary: sections?.orderSummary,
      billingDetails: sections?.billingDetails,
      pageBanner: sections?.pageBanner,
      chatFlow: sections?.enableChatFlow,
    }
  }, [
    sections?.billingDetails,
    sections?.enableChatFlow,
    sections?.orderSummary,
    sections?.pageBanner,
  ])

  const {
    title = '',
    subTitle = '',
    freeShippingText = '',
    paidShippingAmount = '',
  } = shippingCharges || {}
  const shippingChargesModalData = {
    title: title,
    subTitle:
      shippingCharge > 0
        ? freeShippingText
        : subTitle
            ?.replace('__shippingCharge__', shippingCharge.toString())
            ?.replace('__orderAmount__', paidShippingAmount as string),
  }

  const onCTAClick = useCallback(
    (link: ILink, ctaData: {label: string; section: string}) => {
      const {label = '', section = ''} = ctaData || {}
      handleCtaV2(link)
      analytics.trigger('thankYouPageCtaClick', {
        ...analyticsData,
        label,
        section,
      })
    },
    [analytics, analyticsData, handleCtaV2]
  )

  useEffect(() => {
    if (sections?.orderSummary && sections?.billingDetails) {
      analytics.trigger('newThankyouPage', analyticsData)
    }
  }, [
    analytics,
    analyticsData,
    sections?.billingDetails,
    sections?.orderSummary,
  ])

  useEffect(() => {
    if (sections?.orderSummary?.type) {
      getAppRatingModalData({
        type: 'thankYou',
      })
    }
  }, [getAppRatingModalData, sections?.orderSummary?.type])

  return [
    {
      showShippingChargesModal,
      shippingChargesModalData,
      appRatingModalData,
    },
    {
      onShippingChargesClicked: handleShippingInfoClicked,
      onShippingInfoClick: handleShippingInfoClicked,
      onCTAClick,
      onBirthdayTrackerSeen,
      onBirthdayTrackerAddChildClicked,
      onBirthdayTrackerCancelClicked,
      saveKidsData,
      handleCloseClick,
    },
  ]
}
