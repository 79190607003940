import {useCallback} from 'react'
import {useConfigStore} from '@mosaic-wellness/redux-action-library'

import {EVENT_MAP} from 'src/analytics/eventMap'

function useImageUploadTracking() {
  const {analytics} = useConfigStore()

  const onImageUploadViewed = useCallback(
    (data) => {
      analytics.trigger(EVENT_MAP.IMAGE_UPLOAD_VIEWED, data)
    },
    [analytics]
  )

  const onImageUploadInitiated = useCallback(() => {
    analytics.trigger(EVENT_MAP.IMAGE_UPLOAD_INITIATED)
  }, [analytics])

  const onWhatToUploadClicked = useCallback(() => {
    analytics.trigger(EVENT_MAP.WHAT_TO_UPLOAD_CLICKED)
  }, [analytics])

  const onImageSizeExceeded = useCallback(
    (data) => {
      analytics.trigger(EVENT_MAP.IMAGE_SIZE_ERROR, data)
    },
    [analytics]
  )

  const onImageUploadSuccess = useCallback(
    (data) => {
      analytics.trigger(EVENT_MAP.IMAGE_UPLOAD_SUCCESS, data)
    },
    [analytics]
  )

  const onImageFlowExit = useCallback(
    (data) => {
      analytics.trigger(EVENT_MAP.IMAGE_FLOW_EXIT, data)
    },
    [analytics]
  )

  const pushAppointmentDataToDataLayer = useCallback(
    (data) => {
      analytics.push({imageUploadAppointmentDetails: data})
    },
    [analytics]
  )

  return {
    onImageUploadViewed,
    onImageUploadInitiated,
    onWhatToUploadClicked,
    onImageSizeExceeded,
    onImageUploadSuccess,
    onImageFlowExit,
    pushAppointmentDataToDataLayer,
  }
}

export {useImageUploadTracking}
