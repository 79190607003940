import {useCallback, useEffect, useState} from 'react'
import {HttpClient} from '@mosaic-wellness/redux-action-library'
import {BACKEND_BASE_PATH} from 'src/utils/constants/breakPoints'

export function useAppDownloadIncentiveData({
  showAppInstallBannerConfig = {
    showAppInstallBanner: false,
    pageCount: 2,
  },
}) {
  const [data, setData] = useState(null)
  const axiosInstance = HttpClient.getAxiosInstance()

  useEffect(() => {
    const {count = 0} =
      (typeof window !== 'undefined' &&
        JSON.parse(window.sessionStorage.getItem('noOfPDPViewed') || 'null')) ||
      {}
    async function fetchAppIncentiveData() {
      const axiosInstance = HttpClient.getAxiosInstance()
      const {data} = await axiosInstance.get(
        BACKEND_BASE_PATH + 'user/get-app-banner'
      )

      if (data?.data) {
        setData(data?.data)
      }
    }

    if (showAppInstallBannerConfig?.showAppInstallBanner) {
      if (count === showAppInstallBannerConfig?.pageCount) {
        fetchAppIncentiveData()
      } else {
        setData(
          typeof window !== 'undefined' &&
            JSON.parse(
              window.sessionStorage.getItem('appDownloadIncentivePersist') ||
                'null'
            )
        )
      }
    }
  }, [showAppInstallBannerConfig])

  const setNumPdpViewed = useCallback((urlKey) => {
    const {count = 0, lastPDP = ''} =
      (typeof window !== 'undefined' &&
        JSON.parse(window.sessionStorage.getItem('noOfPDPViewed') || 'null')) ||
      {}
    const data = {
      count: count + 1,
      lastPDP: urlKey,
    }
    if (typeof window !== 'undefined' && lastPDP !== urlKey) {
      window.sessionStorage.setItem('noOfPDPViewed', JSON.stringify(data))
    }
  }, [])

  return {AppDownloadIncentiveData: data, setNumPdpViewed}
}
