import {imageWidthMappings} from '../constants/imageWidthMappings'

export function getOptimizedImageKitLink(
  source,
  desktopViewWidth,
  mobileViewWidth,
  isMobile
) {
  if (!!source) {
    let imageKitWidth = isMobile
      ? imageWidthMappings.mobile[mobileViewWidth]
      : imageWidthMappings.desktop[desktopViewWidth]
    const url = new URL(source)
    const hasMWParam = url.searchParams.get('mw') === '1'
    if (!hasMWParam) url.searchParams.set('tr', `w-${imageKitWidth}`)
    return `${url.href},c-at_max`
  }
  return ''
}
