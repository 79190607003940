import forEach from 'lodash/forEach'
import isEmpty from 'lodash/isEmpty'
import noop from 'lodash/noop'
import React, {useCallback, useEffect, useMemo, useState} from 'react'

import {Spinner} from '../../../ComponentsV2/common'
import Typography from '../../../ComponentsV2/common/Typography/Typography'
import NextButton from '../NextButton'
import {ImageUploadWrapper} from './ImageUpload.styles'
import InfoModal from './InfoModal/InfoModal'
import InputContainer from './InputContainer'
import SkipModal from './SkipModal/SkipModal'
import {UploadCallout} from './UploadCallout'

const ImageUpload = (props) => {
  const {
    category = '',
    scheduleToken = '',
    schedule_token = '',
    headingNoImage = '',
    headingOneImage = '',
    headingTwoImage = '',
    headingIcon = '',
    subHeading = '',
    infoText = '',
    infoModalContent = {},
    inputTextNoUpload = '',
    inputTextOneUpload = '',
    inputTextTwoUpload = '',
    inputContent = {},
    inputSubText = '',
    showSkipForCategory = [],
    skipText = '',
    skipModalContent = {},
    skipSubText = '',
    errorMessage = '',
    disableSubmitButtonText = '',
    submitButtonText = '',
    uploadError = false,
    uploaded = null,
    isLoading = false,
    skipImageUpload = {},
    showSkipUploadSection = true,
    callouts = {},
    imageSizeExceededText = '',
  } = props.state || {}

  const {
    imageUpload = noop,
    handleScreenChange = noop,
    trackImageUpload = noop,
    primaryRedirect = noop,
    onImageSizeExceeded = noop,
    onImageUploadInitiated = noop,
    onWhatToUploadClicked = noop,
    getFloatingIslandNudgeData = noop,
    toast = noop,
  } = props.actions || {}

  const [uploadedImageFirst, setUploadedImageFirst] = useState(null)
  const [uploadedImageSecond, setUploadedImageSecond] = useState(null)
  const [skipImageFirst, setSkipImageFirst] = useState('')
  const [skipImageSecond, setSkipImageSecond] = useState('')
  const [showInfoModal, setShowInfoModal] = useState(false)
  const [showSkipModal, setShowSkipModal] = useState(false)

  const {callout1 = {}, callout2 = {}} = callouts

  const [showCallout1, showCallout2] = useMemo(() => {
    return [!isEmpty(callout1), !isEmpty(callout2)]
  }, [callout1, callout2])

  const heading = useMemo(() => {
    if (uploadedImageFirst && uploadedImageSecond) {
      return headingTwoImage
    } else if (uploadedImageFirst || uploadedImageSecond) {
      return headingOneImage
    } else {
      return headingNoImage
    }
  }, [
    uploadedImageFirst,
    uploadedImageSecond,
    headingTwoImage,
    headingOneImage,
    headingNoImage,
  ])

  const inputText = useMemo(() => {
    if (uploadedImageFirst && uploadedImageSecond) {
      return inputTextTwoUpload
    } else if (uploadedImageFirst || uploadedImageSecond) {
      return inputTextOneUpload
    } else {
      return inputTextNoUpload
    }
  }, [
    uploadedImageFirst,
    uploadedImageSecond,
    inputTextTwoUpload,
    inputTextOneUpload,
    inputTextNoUpload,
  ])

  const buttonDisable = useMemo(() => {
    return isLoading || !(uploadedImageFirst || uploadedImageSecond)
  }, [uploadedImageFirst, uploadedImageSecond, isLoading])

  const buttonText = useMemo(() => {
    return buttonDisable ? disableSubmitButtonText : submitButtonText
  }, [buttonDisable, submitButtonText, disableSubmitButtonText])

  const infoModalData = useMemo(() => {
    return infoModalContent[category]
  }, [infoModalContent, category])

  const skipModalData = useMemo(() => {
    return skipModalContent[category]
  }, [skipModalContent, category])

  const showSkipText = useMemo(() => {
    return showSkipForCategory.includes(category)
  }, [showSkipForCategory, category])

  const handleUploadImage = useCallback(
    (image, key) => {
      //analytics trigger
      onImageUploadInitiated()

      if (key == 1) {
        setUploadedImageFirst(image)
      } else if (key == 2) {
        setUploadedImageSecond(image)
      }
    },
    [onImageUploadInitiated]
  )

  const handleImageClose = useCallback((key) => {
    if (key == 1) {
      setUploadedImageFirst(null)
    } else if (key == 2) {
      setUploadedImageSecond(null)
    }
  }, [])

  const handleSkipImageClick = useCallback(
    (image) => {
      if (skipImageFirst === image) {
        setSkipImageFirst('')
      } else if (skipImageSecond === image) {
        setSkipImageSecond('')
      } else if (!skipImageFirst) {
        setSkipImageFirst(image)
      } else {
        setSkipImageSecond(image)
      }
    },
    [skipImageFirst, skipImageSecond]
  )

  const handleInfoModalOpen = useCallback(() => {
    setShowInfoModal(true)
  }, [])

  const handleInfoModalClose = useCallback(() => {
    setShowInfoModal(false)
  }, [])

  const handleInfoTextClicked = useCallback(() => {
    handleInfoModalOpen()
    onWhatToUploadClicked()
  }, [handleInfoModalOpen, onWhatToUploadClicked])

  const handleSkipModalOpen = useCallback(() => {
    setUploadedImageFirst(null)
    setUploadedImageSecond(null)
    setShowSkipModal(true)
    //analytics trigger
    trackImageUpload({type: 'skipUpload'})
  }, [trackImageUpload])

  const handleSkipModalClose = useCallback(() => {
    setSkipImageFirst('')
    setSkipImageSecond('')
    setShowSkipModal(false)
  }, [])

  const handleSkipTextClicked = useCallback(() => {
    if (skipImageUpload[category]) {
      handleScreenChange()
      return
    }
    handleSkipModalOpen()
  }, [category, handleScreenChange, handleSkipModalOpen, skipImageUpload])

  const handleSubmit = useCallback(async () => {
    const formData = new FormData()
    let images = []
    if (uploadedImageFirst) {
      images.push(uploadedImageFirst)
    }
    if (uploadedImageSecond) {
      images.push(uploadedImageSecond)
    }
    if (skipImageFirst) {
      images.push(skipImageFirst)
    }
    if (skipImageSecond) {
      images.push(skipImageSecond)
    }

    forEach(images, (image) => formData.append('files', image))

    imageUpload({
      formData,
      scheduleToken: scheduleToken || schedule_token,
    })
  }, [
    uploadedImageFirst,
    uploadedImageSecond,
    skipImageFirst,
    skipImageSecond,
    imageUpload,
    scheduleToken,
    schedule_token,
  ])

  useEffect(() => {
    if (uploaded) {
      //analytics trigger
      trackImageUpload({
        type: 'uploaded',
        numberOfImages: uploadedImageFirst && uploadedImageSecond ? 2 : 1,
      })
      //Refetch nudges data for bottom floating bar
      getFloatingIslandNudgeData()
      if (handleScreenChange === noop) {
        primaryRedirect()
      } else {
        handleScreenChange()
      }
    }
  }, [uploaded, handleScreenChange, primaryRedirect])

  const skipModalProps = useMemo(() => {
    return {
      state: {
        ...skipModalData,
        showSkipModal,
        skipImageFirst,
        skipImageSecond,
        uploadError,
        isLoading,
      },
      actions: {handleSkipImageClick, handleSubmit, handleSkipModalClose},
    }
  }, [
    skipModalData,
    showSkipModal,
    skipImageFirst,
    skipImageSecond,
    uploadError,
    handleSkipImageClick,
    handleSubmit,
    handleSkipModalClose,
    isLoading,
  ])

  if (isEmpty(props.state)) {
    return null
  }

  return (
    <ImageUploadWrapper>
      <div className="heading-container">
        <div className="heading-icon-container">
          <img className="heading-icon" src={headingIcon} alt="heading icon" />
        </div>
        <div className="heading-text">
          <Typography
            text={heading}
            variant="heading-sm-bold"
            customClassName="image-upload__heading"
          />
          <Typography
            text={subHeading}
            variant="body-base-regular"
            customClassName="image-upload__sub-heading"
          />
        </div>
      </div>
      <div className="image-upload__info-text" onClick={handleInfoTextClicked}>
        {infoText}
      </div>
      <div
        className="image-upload__input-text"
        dangerouslySetInnerHTML={{__html: inputText}}
      />

      {showCallout1 && <UploadCallout state={callout1} />}

      <div className="image-upload__inputs-container">
        <InputContainer
          state={{
            ...inputContent,
            uploadedImage: uploadedImageFirst,
            key: 1,
            imageSizeExceededText,
          }}
          actions={{
            handleUploadImage,
            handleImageClose,
            onImageSizeExceeded,
            onImageUploadInitiated,
            toast,
          }}
        />
        <InputContainer
          state={{
            ...inputContent,
            uploadedImage: uploadedImageSecond,
            key: 2,
            imageSizeExceededText,
          }}
          actions={{
            handleUploadImage,
            handleImageClose,
            onImageSizeExceeded,
            toast,
          }}
        />
      </div>

      <Typography
        text={inputSubText}
        variant="tag-regular"
        customClassName="image-upload__input-sub-text"
      />

      {showCallout2 && <UploadCallout state={callout2} />}

      {showSkipText && showSkipUploadSection && (
        <div>
          <div
            className="image-upload__skip-text"
            onClick={handleSkipTextClicked}
          >
            {skipText}
          </div>
          <div className="image-upload__skip-sub-text">{skipSubText}</div>
        </div>
      )}

      {uploadError && (
        <div className="image-upload__error-text">{errorMessage}</div>
      )}

      <div className="image-upload__submit-button-container">
        <button
          className="image-upload__submit-button"
          disabled={buttonDisable}
          onClick={handleSubmit}
        >
          {isLoading ? <Spinner size={25} /> : buttonText}
        </button>
      </div>
      <NextButton
        state={{
          label: 'Submit',
          isDisabled: buttonDisable,
          isLoading,
        }}
        actions={{
          onClick: handleSubmit,
        }}
        onClick={handleSubmit}
      >
        Submit
      </NextButton>

      <InfoModal
        state={{...infoModalData, showInfoModal}}
        actions={{handleInfoModalClose}}
      />
      <SkipModal {...skipModalProps} />
    </ImageUploadWrapper>
  )
}
export default ImageUpload
