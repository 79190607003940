import {useUpdateCart} from '@mosaic-wellness/redux-action-library'
import {useCallback} from 'react'

interface ICartUpdateData {
  sku: string
  quantity?: number
  getPrescription?: boolean
  isMiniCart?: boolean
  source?: string
}

export function useUpdateItemToCart() {
  const {updateCartItem} = useUpdateCart()

  const addToCart = useCallback(
    ({
      sku,
      getPrescription,
      quantity = 1,
      isMiniCart = true,
      source = '',
    }: ICartUpdateData) => {
      updateCartItem({
        info: {
          action: 'add',
          qty: quantity,
          sku,
        },
        payload: {
          getPrescription,
          isMiniCart,
          source,
        },
      })
    },
    [updateCartItem]
  )

  const removeFromCart = useCallback(
    ({
      sku,
      getPrescription,
      isMiniCart = true,
      quantity = 1,
    }: ICartUpdateData) => {
      updateCartItem({
        info: {
          action: 'remove',
          qty: quantity,
          sku,
        },
        payload: {
          getPrescription,
          isMiniCart,
        },
      })
    },
    [updateCartItem]
  )

  const updateItemToCart = useCallback(
    ({
      sku,
      getPrescription,
      quantity = 1,
      isMiniCart = true,
    }: ICartUpdateData) => {
      updateCartItem({
        info: {
          action: 'update',
          qty: quantity,
          sku,
        },
        payload: {
          getPrescription,
          isMiniCart,
        },
      })
    },
    [updateCartItem]
  )

  return {
    addToCart,
    removeFromCart,
    updateItemToCart,
  }
}
