import {useRouter} from 'next/router'
import {useCallback} from 'react'

function useAppNavigation(props: {appNavAction: string}) {
  const {appNavAction = 'NAVIGATE_BACK'} = props
  const {back} = useRouter()

  const onBackClick = useCallback(() => {
    if (typeof window === 'undefined') {
      return
    }

    if (window.history.state && window.history.state.idx === 0) {
      //@ts-ignore
      window?.ReactNativeWebView?.postMessage(
        JSON.stringify({
          actions: appNavAction,
        })
      )
    } else {
      back()
    }
  }, [appNavAction, back])

  return {
    onBackClick,
  }
}

export {useAppNavigation}
