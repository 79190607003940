import {getQueryParamFromUrl} from './url'

const checkifArrayAndhasElements = (arr: any) =>
  Array.isArray(arr) && arr.length > 0

const getCartSource = () => {
  const intCmpValue = getQueryParamFromUrl('int_cmp')
  if (intCmpValue === 'buyNow') return 'Buy Now Checkout'
  else if (intCmpValue === 'ATCButton') return 'ATC Button'
  else if (intCmpValue === 'PDPNudge') return 'PDP Nudge'
  else return 'Cart Icon'
}

export const getCartDetailsForAnalytics = (
  cart: any,
  hideCod: boolean = false,
  user: any
) => {
  const {
    products,
    totalAmount = 0,
    appliedDiscount,
    rxStatus,
    billingSummary,
    analyticsData = {},
    cartUi = '',
    checkoutInfo = {},
  } = cart || {}
  const {milestoneTier} = checkoutInfo || {}
  const {value} = milestoneTier || {}

  const {walletPoints = 0} = user || {}
  const source = getCartSource()

  const ifCartHasItems = checkifArrayAndhasElements(products)

  const totalItems = ifCartHasItems ? products.length : 0

  const productIds = ifCartHasItems
    ? products
        .map((product: any) => {
          const productURL = product?.item?.productURL
          return productURL || ''
        })
        .join(',')
    : ''

  const productNames = ifCartHasItems
    ? products
        .map((product: any) => {
          const name = product?.item?.name
          return name || ''
        })
        .join(',')
    : ''

  const productSkus = ifCartHasItems
    ? products
        .map((product: any) => {
          const sku = product?.item?.sku
          return sku || ''
        })
        .join('.')
    : ''

  const productCategories = ifCartHasItems
    ? products
        .map((product: any) => {
          const category = product?.item?.category
          return category || ''
        })
        .join('.')
    : ''

  const couponData = appliedDiscount?.discount?.coupon || {}

  const {isApplied: isCouponApplied = false, value: coupon = ''} = couponData
  const {hasPseudoRx = false, hasRx = false, hasInstantRx} = rxStatus || {}

  const {analyticsInfo} = billingSummary || {}
  const {
    discountAmount = 0,
    shippingAmount = 0,
    subtotal = 0,
  } = analyticsInfo || {}

  const isShippingFree = shippingAmount === 0

  let freeGiftsCount = 0

  if (ifCartHasItems) {
    products.forEach((product: any) => {
      const {isFree} = product || {}
      if (isFree) freeGiftsCount += 1
    })
  }

  const walletData = appliedDiscount?.discount?.wallet || {}
  const {isApplied: isWalletApplied = false} = walletData
  const URL = typeof window !== 'undefined' ? `${window.location.href}` : ''

  const getCartType = () => {
    if (hasInstantRx) {
      return 'Instant RX'
    }

    if (hasRx) {
      return 'RX'
    }

    if (hasPseudoRx) {
      return 'OPT_RX'
    }

    return 'OTC'
  }

  return {
    productIds,
    productNames,
    productSkus,
    totalItems,
    totalAmount,
    isCouponApplied,
    coupon,
    codBlocked: hideCod,
    source,
    rxStatus: hasRx,
    cartType: getCartType(),
    isShippingFree,
    shippingValue: shippingAmount,
    freeGiftsCount,
    isWalletApplied,
    walletPoints,
    url: URL,
    discountValue: discountAmount,
    productCategories,
    email: user?.email || '',
    phoneNumber: user?.phoneNumber || '',
    subTotal: subtotal,
    analyticsData,
    cartUi,
    milestoneTierValue: value ? value : undefined,
  }
}
