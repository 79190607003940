const getProductIdsFromProducts = (products: any) => {
  return products.map((product: any) => product.item.productURL)
}

const getProductNamesFromProducts = (products: any) => {
  return products.map((product: any) => product.item.name)
}

export const processRxCartViewed = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {products = [], totalAmount = null} = data

  return {
    'Product ID': getProductIdsFromProducts(products).toString(),
    'Product Items': getProductNamesFromProducts(products).toString(),
    'Cart Value': totalAmount || 0,
  }
}

export const processCheckoutClickedRxCart = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {products = [], totalAmount = null} = data

  return {
    'Product ID': getProductIdsFromProducts(products),
    'Product Items': getProductNamesFromProducts(products),
    'Order Value': totalAmount,
  }
}

export const processProductRemovedFromCart = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {id, name, rxType = ''} = data

  return {
    'Product Id': id,
    'Product Name': name,
    Essential: rxType.toLowerCase() === 'essential' ? 'Y' : 'N',
    Mandatory: rxType.toLowerCase() === 'mandatory' ? 'Y' : 'N',
  }
}

export const processAddToCartRxCart = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {id, name} = data

  return {
    'Product Id': id,
    'Product Name': name,
  }
}

export const processProductInfoViewed = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {id, name} = data

  return {
    'Product Id': id,
    'Product Name': name,
  }
}

export const processPdpViewed = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {id, name} = data

  return {
    'Product Id': id,
    'Product Name': name,
  }
}
