import {HttpClient, useConfigStore} from '@mosaic-wellness/redux-action-library'
import React, {useCallback, useMemo, useRef, useState} from 'react'
import {EVENT_MAP} from 'src/analytics/eventMap'
import {API_END_POINTS} from 'src/constants/apiEndPoints'
import {BACKEND_BASE_PATH} from 'src/utils/constants/breakPoints'
import {useGenericCta} from '../useGenericCta'
import {
  convertToCms,
  getBMIResult,
} from 'src/components/shared/src/ComponentsV2/common/ChildDetailsBottomSheet/utils'
import {FormFields} from 'src/components/shared/src/ComponentsV2/common/ChildDetailsBottomSheet/ChildDetails.interface'
import {checkIsApp} from 'src/utils/checkIsApp'
import isArray from 'lodash/isArray'

function useChildDetails(props: any) {
  const {formData = []} = props || {}
  const [childDetailsData, setChildDetailsData] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [showChildBottomSheet, setChildBottomSheet] = useState(false)
  const [showBmiReport, setShowBmiReport] = useState(false)
  const [bmiReportData, setBmireportData] = useState([])
  const [childDetails, setChildDetails] = useState([])
  const [showModal, setShowModal] = useState(true)
  const [isError, setIsError] = useState(false)
  const isApp = checkIsApp()

  const [selectedParent, setSelectedParent] = useState(null)
  const [expandedIndex, setExpandedIndex] = useState(0)

  const {handleCta} = useGenericCta()
  const axiosInstance = HttpClient.getAxiosInstance()
  const {analytics} = useConfigStore()

  const {GET_FORM_DATA, SUBMIT_FORM_DATA} = API_END_POINTS

  const getChildFormdata = useCallback(async (source = '') => {
    setIsLoading(true)

    try {
      const {data} = await axiosInstance.get(
        `${BACKEND_BASE_PATH}${GET_FORM_DATA}?page_source=${source}`
      )

      setChildDetailsData(data?.data)
    } catch (error) {
      // do nothing
    } finally {
      setIsLoading(false)
    }
  }, [])

  const submitChildFormData = useCallback(async (childData) => {
    setIsLoading(true)
    try {
      const {data} = await axiosInstance.post(
        `${BACKEND_BASE_PATH}${SUBMIT_FORM_DATA}`,
        {...childData}
      )

      const {redirectEnbled} = data?.data || {}
      const {addChildData} = childData || {}

      //child details filled analytics
      addChildData.map((child: any) => {
        const {data, ...rest} = child || {}
        analytics.trigger(EVENT_MAP.CHILD_DETAILS_FILLED, rest)
      })

      const transformedData = addChildData?.reduce((result, data, index) => {
        let bmiData = data?.data
        try {
          bmiData = JSON.parse(bmiData || {})
        } catch (e) {}
        const childNameKey = `childName${index + 1}`
        const childGenderKey = `childGender${index + 1}`
        const bmiKey = `childBmi${index + 1}`
        const bmiStatusKey = `childBmiStatus${index + 1}`

        result[childNameKey] = data?.childName || ''
        result[childGenderKey] = data?.childGender || ''
        result[bmiKey] = bmiData?.bmi || ''
        result[bmiStatusKey] = bmiData?.bmiStatus || ''

        return result
      }, {})

      //bmi report bottomsheet viewed analytics
      analytics.trigger(EVENT_MAP.CHILD_DETAILS_BMI_VIEWED, transformedData)
      setShowBmiReport(true)

      // *!TODO: currently not in use
      // window?.ReactNativeWebView?.postMessage(
      //   JSON.stringify({
      //     actions: 'BOTTOM_TAB',
      //     actionData: {
      //       name: 'HabitHome',
      //       replaceNav: true,
      //     },
      //   })
      // )

      return
    } catch (error) {
      // do nothing
    } finally {
      setIsLoading(false)
    }
  }, [])

  const calculateChildBmiData = useCallback((childData) => {
    const {
      childGender = '',
      dob = '',
      currentHeight,
      currentWeight = '',
      childName = '',
    } = childData || {}

    const result = getBMIResult({
      childGender,
      dob,
      currentHeight,
      currentWeight,
    })

    setBmireportData((prev) => [
      ...prev,
      {...result, name: childName, gender: childGender},
    ])
    return result
  }, [])

  // Switching between child-details and child-bmi report bottomsheet
  const handleSwitchModal = (action: any) => {
    const {
      showChildBottomSheet = false,
      getAppAction = false,
      link = '',
    } = action || {}

    setShowBmiReport(false)
    //closing bmi-report and showing child details form
    if (showChildBottomSheet) {
      setChildBottomSheet(true)
      setBmireportData([])
    }

    // Get app button click
    if (getAppAction) {
      handleCta({action: 'LINK', link})
    }
  }

  //  * * Toggel Accordion
  const toggleAccordionItem = useCallback(
    (toggle = false, index: any) => {
      if (toggle) {
        setExpandedIndex((prevIndex: any) =>
          prevIndex === index ? null : index
        )
      } else {
        setExpandedIndex(index)
      }
    },
    [expandedIndex]
  )

  const initialState = useMemo(() => {
    return Object.fromEntries(
      formData.map((field: FormFields) => {
        const {id = '', type = ''} = field || {}
        return [id, type === 'checkbox' ? false : '']
      })
    )
  }, [formData])

  const showModalFlag = useMemo(() => {
    return isApp
  }, [isApp])

  // * * Parent Gender change
  const handleParentChange = useCallback(
    (e: {target: {value: any}}) => {
      setSelectedParent(e.target.value)
    },
    [selectedParent]
  )

  // * * Child Gender change
  const handleChildGenderChange = (value: string, index: number) => {
    const updatedChildDetails = [...childDetails]

    updatedChildDetails[index] = {
      ...updatedChildDetails[index],
      childGender: value,
    }
    setChildDetails(updatedChildDetails)
  }

  // * * Add new child
  const handleAddChild = useCallback(() => {
    setChildDetails([
      ...childDetails,
      {
        ...initialState,
        currentHeight: {
          unit: 'feet',
          feet: '',
          inches: '',
          cms: '',
        },
        dob: '',
        childGender: '',
        deleteIcon: true,
        updateLatercurrentHeight: false,
        updateLatercurrentWeight: false,
      },
    ])
    toggleAccordionItem(false, childDetails.length)
  }, [childDetails])

  // * * Child Details change
  const handleChildDetailChange = useCallback(
    (index: number | string, key: string, value: any) => {
      const numericIndex =
        typeof index === 'number' ? index : parseInt(index, 10)

      const updatedChildDetails = [...childDetails]
      if (key === 'childName') {
        let inputValue = value.replace(/[0-9]/g, '')

        updatedChildDetails[numericIndex][key] = inputValue
      } else {
        updatedChildDetails[numericIndex][key] = value
      }

      setChildDetails(updatedChildDetails)
    },
    [childDetails]
  )

  // * * Child Height Unit change
  const handleHeightUnitChange = useCallback((index: number, unit: string) => {
    setChildDetails((prevChildDetails: any) => {
      const updatedChildDetails = [...prevChildDetails]

      if (updatedChildDetails[index].currentHeight.unit === 'feet') {
        updatedChildDetails[index].currentHeight.cms = ''
      }

      if (updatedChildDetails[index].currentHeight.unit === 'cms') {
        updatedChildDetails[index].currentHeight.feet = ''
        updatedChildDetails[index].currentHeight.inches = ''
      }

      updatedChildDetails[index].currentHeight.unit = unit
      return updatedChildDetails
    })
  }, [])

  // * * Remove child
  const removeChild = useCallback((index: number) => {
    setChildDetails((prevChildDetails: any) => {
      const updatedChildDetails = [...prevChildDetails]

      updatedChildDetails.splice(index, 1)
      return updatedChildDetails
    })
  }, [])

  const handleUpdateLater = useCallback(
    (e: {target: {checked: any; value: any}}, id: number, key: string) => {
      const checked = e.target.checked
      const value = e.target.value

      const updatedDetails = [...childDetails]
      updatedDetails[id][key] = checked ? true : false

      setChildDetails(updatedDetails)
    },
    [childDetails]
  )

  const handleClose = useCallback(() => {
    setShowModal(false)
  }, [showModal])

  const validateForm = useCallback(
    (child: ChildDetails, index: string | number) => {
      return Object.entries(child).some((item) => {
        const [key, value] = item
        const id = +index
        if (typeof value === 'object' && !isArray(value)) {
          // If the value is an object, recursively check its values
          return Object.values(value).some((nestedValue) => {
            let {unit, feet, cms, inches} = value

            if (unit === 'feet') {
              return childDetails[id].updateLatercurrentHeight
                ? false
                : feet === '' || feet < 0
            } else if (unit == 'cms') {
              return childDetails[id].updateLatercurrentHeight
                ? false
                : cms === '' || cms < 0
            }
          })
        } else if (key === 'currentWeight') {
          return childDetails[id].updateLatercurrentWeight
            ? false
            : value === '' || value < 0
        } else {
          return value === ''
        }
      })
    },
    [childDetails]
  )

  const submitForm = async (e: {preventDefault: () => void}) => {
    e.preventDefault()
    setIsLoading(true)
    let shouldBreak = false

    childDetails.forEach((child: any, index: any) => {
      if (!shouldBreak) {
        const isAnyValueEmpty = validateForm(child, index)

        if (isAnyValueEmpty) {
          shouldBreak = true
          setIsError(true)
          toggleAccordionItem(false, index)
        }
      }
    })

    if (!shouldBreak) {
      let data = childDetails.map((value: {}) => {
        const {
          childName,
          childGender,
          currentWeight,
          dob,
          currentHeight,
          updateLatercurrentHeight,
          updateLatercurrentWeight,
        } = value || {}
        const {unit, feet, inches, cms} = currentHeight || {}

        let childData = {
          childGender: childGender,
          currentWeight: currentWeight,
          parentGender: selectedParent,
          childName: childName,
          dob: dob,
          updateLatercurrentHeight,
          updateLatercurrentWeight,
        }

        if (unit === 'feet') {
          const height = convertToCms(feet, inches)
          childData = {
            ...childData,
            currentHeight: height,
            heightInFeet: feet,
            heightInInches: inches,
          }
        } else {
          childData = {
            ...childData,
            currentHeight: cms,
            heightInCms: cms,
          }
        }

        const {bmi = '', bmiStatus = ''} = calculateChildBmiData(childData)

        if (bmi && bmiStatus) {
          childData['data'] = {bmi, bmiStatus}
        }

        return childData
      })

      submitChildFormData({addChildData: [...data], taskId: ''})
      handleClose()
      setChildBottomSheet(false)
      setShowBmiReport(true)
    }
  }

  return {
    state: {
      isLoading,
      childDetailsData,
      showChildBottomSheet,
      showBmiReport,
      bmiReportData,
      childDetails,
      selectedParent,
      expandedIndex,
      showModal,
      showModalFlag,
      initialState,
      isError,
    },
    actions: {
      getChildFormdata,
      submitChildFormData,
      handleSwitchModal,
      calculateChildBmiData,
      setChildBottomSheet,
      submitForm,
      handleUpdateLater,
      removeChild,
      handleHeightUnitChange,
      handleAddChild,
      handleParentChange,
      handleChildGenderChange,
      setChildDetails,
      setShowModal,
      toggleAccordionItem,
      handleChildDetailChange,
      setIsError,
      setShowBmiReport,
    },
  }
}

export {useChildDetails}
