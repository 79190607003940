import {useCallback} from 'react'
export const useDAViewed = () => {
  const setDAViewed = useCallback(() => {
    if (typeof window !== 'undefined')
      window.sessionStorage.setItem('daViewed', JSON.stringify(true))
  }, [])

  const removeDAViewed = useCallback(() => {
    if (typeof window !== 'undefined')
      window.sessionStorage.removeItem('daViewed')
  }, [])

  return {
    setDAViewed,
    removeDAViewed,
  }
}
