import {useAction, useConfigStore} from '@mosaic-wellness/redux-action-library'
import {useCallback} from 'react'
import {SCREEN_PATH_NAMES} from 'src/utils/constants/screenPathNames'
import {useProductSwitchActions} from '../product/useSwitchNavigation'
import {useExplicitATCTracking} from '../tracking/useExplicitATCTracking'
import {useUpdateItemToCart} from '../updateCart/useUpdateItemToCart'

import {useGenericCta} from '../useGenericCta'
import {IUseCategoryPage} from './useCategoryPage.interface'
import {EVENT_MAP} from 'src/analytics/eventMap'

function useCategoryPage(props: IUseCategoryPage) {
  const {handleCta} = useGenericCta()
  const {navigateTo} = useAction()
  const {analytics} = useConfigStore()
  const switchActions = useProductSwitchActions()
  const {addToCart} = useUpdateItemToCart()
  const {trackAddToCart} = useExplicitATCTracking()

  const handleGenericAddToCart = useCallback(
    (product: any, source) => {
      const {sku} = product
      trackAddToCart(product, source)
      addToCart({sku})
    },
    [trackAddToCart, addToCart]
  )

  const {category = ''} = props || {}

  const onCtaClicked = useCallback(
    (link = '', analyticsData = {}, title = '') => {
      const {eventData = {}} = analyticsData
      analytics.trigger(EVENT_MAP.CTA_CLICKED, {
        ...eventData,
        label: title,
        link,
      })
      handleCta({action: 'LINK', link})
    },
    [analytics, handleCta]
  )

  const onCartClick = useCallback(() => {
    onCtaClicked(SCREEN_PATH_NAMES.CHECKOUT)
  }, [onCtaClicked])

  //adding theme=rcl temporarily for routing in BW staging
  const onConcernClick = useCallback(
    ({link, type, redirectionData}) => {
      analytics.trigger('concernClick', {category, type})
      if (!!link) navigateTo(`/concern${link}?theme=rcl`)
      else handleCta(redirectionData)
    },
    [analytics, category, navigateTo, handleCta]
  )

  const onGetRegimeCardClick = useCallback(
    () => analytics.trigger('filterClicked', {category}),
    [analytics, category]
  )

  const onFilterApplied = useCallback(
    ({question, answer, filters}) => {
      analytics.trigger('filterApplied', {
        question,
        answer,
        filters,
      })
    },
    [analytics]
  )

  return {
    switch: switchActions,
    onGenericAddToCart: handleGenericAddToCart,
    onCtaClicked,
    onCartClick,
    onConcernClick,
    onGetRegimeCardClick,
    onFilterApplied,
  }
}

export {useCategoryPage}
