import React from 'react'
import {useAuthBoot} from 'src/hooks/useAuthorizedNav/useAuthBoot'
import {useUtmExperimentsBoot} from 'src/hooks/useUtmExperiments/useUtmExperiments'

function Boot() {
  useAuthBoot()
  useUtmExperimentsBoot()
  return null
}

export default Boot
