import {useCallback, useEffect, useRef, useState} from 'react'

import {IUseRescheduleScreenProps} from './useRescheduleScreen.interface'
import {
  useConfigStore,
  useFloatingIslandNudgesData,
  useGetAppointmentDetails,
  useRescheduleAppointment,
  useCommsActiveRoute,
} from '@mosaic-wellness/redux-action-library'
import {useGenericCta, useDaScheduleScreen} from 'src/hooks'
import useRescheduleScreenTracking from 'src/hooks/tracking/useRescheduleScreenTracking'
import {
  getCategoryFromAppointmentCategory,
  getTransformSlotsData,
} from 'src/utils'
import {checkIsApp} from 'src/utils/checkIsApp'

const useRescheduleScreen = (props: IUseRescheduleScreenProps) => {
  const {
    consultConfigData,
    scheduleTokenFromHook = '',
    isRescheduleScreen = true,
  } = props
  const [category, setCategory] = useState('hair')
  const [scheduleToken, setScheduleToken] = useState<string>(
    scheduleTokenFromHook
  )
  const warningToastShown = useRef(false)
  const [pageLoader, setPageLoader] = useState(true)
  const initialLoadingRef = useRef(false)
  const isApp = checkIsApp()

  const {
    environment: {toast},
  } = useConfigStore()

  const [daScheduleScreenState, daScheduleScreenActions] = useDaScheduleScreen({
    scheduleScreenData: consultConfigData.reScheduleScreen,
    category,
  })
  const [{data}] = useCommsActiveRoute()

  const supportedCategories =
    consultConfigData?.scheduleScreen?.supportedCategories

  const {scheduleScreenUIData = {}, slotData} = daScheduleScreenState
  const {errorMessages = ''} = scheduleScreenUIData
  const {rescheduleDisabledMessage = ''} = errorMessages

  const [rescheduleAppointmentState, rescheduleAppointmentActions] =
    useRescheduleAppointment()

  const [
    {appointmentDetails, isAppointmentDataLoading},
    {getAppointmentDetails},
  ] = useGetAppointmentDetails()

  const {handleCta} = useGenericCta()

  const {
    onRescheduleScreenViewed,
    onRescheduleLanguageChanged,
    onRescheduleScreenConfirmClicked,
  } = useRescheduleScreenTracking()

  const [{}, {getFloatingIslandNudgeData}] = useFloatingIslandNudgesData()

  const closeRescheduleModal = useCallback(() => {
    if (isApp) {
      handleCta({
        actions: 'SPA_LINK',
        link: data?.link,
      })
      window.location.href = ''
      return
    }
    //TODO: refactor and use switch case here in
    if (
      !appointmentDetails?.imageUploaded &&
      supportedCategories.includes(appointmentDetails?.categories[0])
    ) {
      handleCta({
        action: 'SPA_LINK',
        link: `upload-image?schedule_token=${scheduleToken}`,
      })
    } else if (
      appointmentDetails?.imageUploaded &&
      supportedCategories.includes(appointmentDetails?.categories[0])
    ) {
      handleCta({
        action: 'SPA_LINK',
        link: `confirmation-screen?schedule_token=${scheduleToken}`,
      })
    } else {
      handleCta({
        action: 'SPA_LINK',
        link: `confirmation-screen?schedule_token=${scheduleToken}`,
      })
    }
  }, [handleCta, isApp, data?.link])

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const scheduleToken = urlParams.get('schedule_token')

    if (scheduleToken) {
      setScheduleToken(scheduleToken)
    }
  }, [])

  useEffect(() => {
    const finalScheduleToken = scheduleToken || scheduleTokenFromHook
    if (finalScheduleToken) {
      getAppointmentDetails({scheduleToken: finalScheduleToken})
    }
  }, [getAppointmentDetails, scheduleToken, scheduleTokenFromHook])

  useEffect(() => {
    if (appointmentDetails) {
      setCategory(appointmentDetails?.categories[0])
      if (
        !appointmentDetails?.canReschedule &&
        warningToastShown.current === false &&
        isRescheduleScreen
      ) {
        toast('warning', rescheduleDisabledMessage)
        warningToastShown.current = true
      }
      // analytics
      onRescheduleScreenViewed({
        ...appointmentDetails,
        availableSlot: getTransformSlotsData(slotData),
      })
    }
  }, [
    appointmentDetails,
    isRescheduleScreen,
    onRescheduleScreenViewed,
    rescheduleDisabledMessage,
    slotData,
    toast,
  ])

  useEffect(() => {
    if (isAppointmentDataLoading) {
      initialLoadingRef.current = true
    } else if (initialLoadingRef.current) {
      setPageLoader(false)
      initialLoadingRef.current = false
    }
  }, [isAppointmentDataLoading, pageLoader])

  const rescheduleAppointmentCardProps = {
    ...appointmentDetails,
    ...consultConfigData?.reScheduleScreen?.rescheduleAppointmentCard,
    isAppointmentDataLoading,
  }

  return [
    {
      ...daScheduleScreenState,
      ...rescheduleAppointmentState,
      isReschedule: true,
      rescheduleAppointmentCardProps,
      schedule_token: scheduleToken,
      isRescheduleDisabled: !appointmentDetails?.canReschedule,
      categoryForContainer: getCategoryFromAppointmentCategory(category),
      pageLoader,
    },
    {
      ...daScheduleScreenActions,
      ...rescheduleAppointmentActions,
      closeRescheduleModal,
      onRescheduleLanguageChanged,
      onRescheduleScreenConfirmClicked,
      getFloatingIslandNudgeData,
    },
  ]
}

export {useRescheduleScreen}
