import {useMemo, useCallback, useState, useEffect} from 'react'
import noop from 'lodash/noop'
import {useRouter} from 'next/router'
import isEmpty from 'lodash/isEmpty'

import {useGenericCtaV2, useRescheduleScreen} from '..'
import useCommsHomepageTracking from '../tracking/useCommsHomepgaeTracking'
import {
  useCancelAppointment,
  useConfigStore,
  useUserContent,
  useCommsActiveRoute,
  useGetAppRatingModalData,
} from '@mosaic-wellness/redux-action-library'
import {checkIsApp} from 'src/utils/checkIsApp'
import {useAppNavigation} from '../../hooks'
import {getCategoryFromAppointmentCategory} from 'src/utils'

interface IUseCommsHomePageProps {
  consultConfigData: any
  version?: string
}

function useCommsHomePage(props: IUseCommsHomePageProps) {
  const {consultConfigData, version = ''} = props
  const [commsFomoModal, setCommsFomoModal] = useState<Record<string, any>>({
    isVisible: false,
    config: {},
  })
  const {handleCtaV2 = noop} = useGenericCtaV2()
  const isApp = checkIsApp()
  const router = useRouter()
  const [{}, {updateCommsActiveRoute}] = useCommsActiveRoute()

  const activeCommsHomeRoute = router?.asPath

  const [{userContent, isLoading}, {getUserContent}] = useUserContent({
    types: 'CONSULT_HOME',
  })
  const [showRescheduleModal, setRescheduleModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [showCancelCard, setShowCancelCard] = useState(false)
  const [showRCLHeader, setShowRCLHeader] = useState('')

  const scheduleTokenFromHook = useMemo(() => {
    if (isEmpty(userContent?.CONSULT_HOME?.actionCards)) {
      return ''
    }
    return userContent?.CONSULT_HOME?.actionCards[0]?.details?.scheduleToken
  }, [userContent?.CONSULT_HOME?.actionCards])

  const [rescheduleScreenState, rescheduleScreenActions] = useRescheduleScreen({
    consultConfigData,
    scheduleTokenFromHook,
    isRescheduleScreen: false,
  })

  const {
    actions: {cancelAppointment},
  } = useCancelAppointment()

  const {analytics} = useConfigStore()

  const [{appRatingModalData}, {getAppRatingModalData}] =
    useGetAppRatingModalData()

  const {
    onCommsSectionViewed,
    onCommsConsultStateCard,
    onCommsCardCTAClicked,
    onCommsEditApptClicked,
    onCommsEditApptActionClicked,
    onCommsRescheduleConfirmed,
    onCommsDAClicked,
    onCommsFormClicked,
    onCommsConsultTrackerClicked,
    onCommsPastPrescriptionsClicked,
    onCommsPopUpCTAClicked,
    onCommsPopUpViewed,
    onCommsPopUpClosed,
  } = useCommsHomepageTracking()

  const {onBackClick} = useAppNavigation({
    appNavAction: 'NAVIGATE_BACK',
  })

  const actionCards = useMemo(() => {
    return userContent?.CONSULT_HOME?.actionCards || []
  }, [userContent?.CONSULT_HOME?.actionCards])

  const category = useMemo(() => {
    if (actionCards[0]) {
      if (
        Array.isArray(actionCards[0]?.details?.rxCategories) &&
        actionCards[0]?.details?.rxCategories[0]
      ) {
        return getCategoryFromAppointmentCategory(
          actionCards[0].details.rxCategories[0]
        )
      }
    }

    return ''
  }, [actionCards])

  const cancelCardData = useMemo(
    () => consultConfigData?.successScreen?.cancelCard,
    [consultConfigData?.successScreen?.cancelCard]
  )

  const dummyActionCards = useMemo(
    () => consultConfigData?.dummyActionCards,
    [consultConfigData?.dummyActionCards]
  )

  const apptType = useMemo(
    () => userContent?.CONSULT_HOME?.actionCards[0]?.type,
    [userContent?.CONSULT_HOME?.actionCards]
  )

  const cardDetails = useMemo(
    () => userContent?.CONSULT_HOME?.actionCards[0]?.details,
    [userContent?.CONSULT_HOME?.actionCards]
  )

  const handleShowRescheduleModal = useCallback(
    () => setRescheduleModal(!showRescheduleModal),
    [showRescheduleModal]
  )

  const handleShowEditModal = useCallback(() => {
    setShowEditModal(!showEditModal)
    onCommsEditApptClicked({
      categoryTitle: cardDetails?.categoryTitle,
      appointmentReference: cardDetails?.appointmentReference,
      imageUploaded: cardDetails?.imageUploaded,
      basicDetailsUpdated: cardDetails?.basicDetailsUpdated,
      source: cardDetails?.source,
      appointmentType: cardDetails?.appointmentType,
      mode: cardDetails?.mode,
    })
  }, [
    cardDetails?.appointmentReference,
    cardDetails?.appointmentType,
    cardDetails?.basicDetailsUpdated,
    cardDetails?.categoryTitle,
    cardDetails?.imageUploaded,
    cardDetails?.mode,
    cardDetails?.source,
    onCommsEditApptClicked,
    showEditModal,
  ])

  const handleShowCancelCard = useCallback(
    () => setShowCancelCard(!showCancelCard),
    [showCancelCard]
  )

  const handleCancelAppointment = useCallback(
    async (appointmentId: string) => {
      await cancelAppointment({
        appointmentId,
      })
      window.location.href = ''
      handleCtaV2({action: 'CLEAR_APPOINTMENT_DETAILS_CONTEXT'})
    },
    [cancelAppointment, handleCtaV2]
  )

  const onCTAClick = useCallback(
    (type, link) => {
      if (type === 'RESCHEDULE') {
        handleShowRescheduleModal()
        onCommsCardCTAClicked({
          type: apptType,
          appointmentState: apptType,
          categoryTitle: cardDetails?.categoryTitle,
          appointmentReference: cardDetails?.appointmentReference,
          imageUploaded: cardDetails?.imageUploaded,
          basicDetailsUpdated: cardDetails?.basicDetailsUpdated,
          source: cardDetails?.source,
          appointmentType: cardDetails?.appointmentType,
          mode: cardDetails?.mode,
        })
        return
      }
      if (type === 'IMAGE_UPLOAD') {
        //@ts-ignore
        window?.ReactNativeWebView?.postMessage(
          JSON.stringify({
            actions: 'REQUEST_CAMERA_PERMISSION',
          })
        )
      }
      handleCtaV2(link)
      onCommsCardCTAClicked({
        type: apptType,
        appointmentState: apptType,
        categoryTitle: cardDetails?.categoryTitle,
        appointmentReference: cardDetails?.appointmentReference,
        imageUploaded: cardDetails?.imageUploaded,
        basicDetailsUpdated: cardDetails?.basicDetailsUpdated,
        source: cardDetails?.source,
        appointmentType: cardDetails?.appointmentType,
        mode: cardDetails?.mode,
      })
    },
    [
      apptType,
      cardDetails?.appointmentReference,
      cardDetails?.appointmentType,
      cardDetails?.basicDetailsUpdated,
      cardDetails?.categoryTitle,
      cardDetails?.imageUploaded,
      cardDetails?.mode,
      cardDetails?.source,
      handleCtaV2,
      handleShowRescheduleModal,
      onCommsCardCTAClicked,
    ]
  )

  const triggerPopUpEvents = useCallback(
    ({type, action}) => {
      let analyticsCallBack = noop
      switch (action) {
        case 'VIEWED': {
          analyticsCallBack = onCommsPopUpViewed
          break
        }
        case 'CLICKED': {
          analyticsCallBack = onCommsPopUpCTAClicked
          break
        }
        case 'CLOSED': {
          analyticsCallBack = onCommsPopUpClosed
          break
        }
        default:
          break
      }

      switch (type) {
        case 'HABIT': {
          analyticsCallBack({
            type: type === 'HABIT' ? 'Track Progress' : 'Past Prescription',
            stateViewed: apptType,
          })
          break
        }
        case 'PAST_APPOINTMENTS': {
          analyticsCallBack({
            type: type === 'HABIT' ? 'Track Progress' : 'Past Prescription',
            stateViewed: apptType,
          })
          break
        }
        default:
          break
      }
    },
    [apptType, onCommsPopUpCTAClicked, onCommsPopUpClosed, onCommsPopUpViewed]
  )

  const fomoCardPrimaryCTAClicked = useCallback(
    ({link, type}) => {
      handleCtaV2(link)
      triggerPopUpEvents({type, action: 'CLICKED'})
    },
    [handleCtaV2, triggerPopUpEvents]
  )

  const fomoCardSecondaryCTAClicked = useCallback(
    ({type}) => {
      setCommsFomoModal(() => ({
        isVisible: false,
      }))
      triggerPopUpEvents({type, action: 'CLOSED'})
    },
    [triggerPopUpEvents]
  )

  const onCTACardClick = useCallback(
    ({link = '', disabled = false, disabledConfig = {}, type = ''}) => {
      switch (type) {
        case 'DA': {
          onCommsDAClicked({
            disabled,
            entryPoint: 'Consult',
            stateViewed: apptType,
          })

          try {
            const basePath = window?.location?.origin || ''
            const urlObject = new URL(`${basePath}${link?.data?.link}`)

            const entryPoint = urlObject.searchParams.get('entryPoint')

            analytics.push({commsDaEntryPoint: entryPoint})
          } catch (err) {}
          break
        }
        case 'FORM': {
          onCommsFormClicked({
            disabled,
            entryPoint: 'Consult',
            stateViewed: apptType,
          })
          break
        }
        case 'HABIT': {
          onCommsConsultTrackerClicked({
            disabled,
            entryPoint: 'Consult',
            stateViewed: apptType,
          })
          break
        }
        case 'PAST_PRESCRIPTIONS': {
          onCommsPastPrescriptionsClicked({
            disabled,
            entryPoint: 'Consult',
            stateViewed: apptType,
          })
          break
        }
        default:
          break
      }
      if (disabled) {
        triggerPopUpEvents({
          type,
          action: 'VIEWED',
        })
        setCommsFomoModal(() => ({
          isVisible: !commsFomoModal.isVisible,
          config: {...disabledConfig, type},
        }))
        return
      }
      handleCtaV2(link)
    },
    [
      apptType,
      commsFomoModal.isVisible,
      handleCtaV2,
      onCommsConsultTrackerClicked,
      onCommsDAClicked,
      onCommsFormClicked,
      onCommsPastPrescriptionsClicked,
      triggerPopUpEvents,
      analytics,
    ]
  )

  useEffect(() => {
    getUserContent({
      version,
    })
  }, [getUserContent, version])

  useEffect(() => {
    getAppRatingModalData({
      type: 'consult',
    })
  }, [getAppRatingModalData])

  useEffect(() => {
    if (appRatingModalData?.displayReviewModal && isApp) {
      window?.ReactNativeWebView?.postMessage(
        JSON.stringify({
          actions: 'OPEN_RATING_MODAL',
          actionData: {
            modalData: appRatingModalData?.modalData,
            trigger: 'PCI post consultation',
            screen: 'Consult Homepage',
          },
        })
      )
      return
    }
  }, [appRatingModalData, isApp])

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const showRCLHeader = urlParams.get('showRCLHeader')

    if (showRCLHeader) {
      setShowRCLHeader(showRCLHeader)
    }
  }, [])

  useEffect(() => {
    const {query} = router

    if (
      !query.schedule_token &&
      scheduleTokenFromHook &&
      !isApp &&
      isEmpty(version)
    ) {
      const updatedQuery = {...query, schedule_token: scheduleTokenFromHook}

      router.push({
        pathname: router.pathname,
        query: updatedQuery,
      })
    }
  }, [isApp, router, scheduleTokenFromHook, version])

  useEffect(() => {
    onCommsSectionViewed({
      stateViewed: apptType,
      futureAppointment: cardDetails?.isFuture,
      pendingAppointment: cardDetails?.hasPendingOrderAppointment,
      rxExists: cardDetails?.hasRx,
    })

    onCommsConsultStateCard({
      categoryTitle: cardDetails?.categoryTitle,
      type: apptType,
      appointmentReference: cardDetails?.appointmentReference,
      imageUploaded: cardDetails?.imageUploaded,
      basicDetailsUpdated: cardDetails?.basicDetailsUpdated,
      source: cardDetails?.source,
      appointmentType: cardDetails?.appointmentType,
      mode: cardDetails?.mode,
    })
  }, [
    cardDetails?.hasPendingOrderAppointment,
    cardDetails?.hasRx,
    cardDetails?.isFuture,
    apptType,
    onCommsSectionViewed,
    cardDetails?.categoryTitle,
    cardDetails?.appointmentReference,
    cardDetails?.imageUploaded,
    cardDetails?.basicDetailsUpdated,
    cardDetails?.source,
    cardDetails?.appointmentType,
    cardDetails?.mode,
    onCommsConsultStateCard,
  ])

  useEffect(() => {
    updateCommsActiveRoute({
      link: activeCommsHomeRoute,
    })
  }, [activeCommsHomeRoute, updateCommsActiveRoute])

  const commHomeProps = useMemo(() => {
    return [
      {
        showCommsFomoModal: commsFomoModal?.isVisible,
        fomoModalConfig: commsFomoModal?.config,
        dummyActionCards,
        userContent: userContent?.CONSULT_HOME,
        showRescheduleModal,
        rescheduleScreenState,
        showEditModal,
        showCancelCard,
        cancelCardData,
        scheduleTokenFromUserContent:
          userContent?.CONSULT_HOME?.actionCards[0]?.details?.scheduleToken,
        isLoading,
        showRCLHeader,
        category,
      },
      {
        onCTACardClick,
        fomoCardPrimaryCTAClicked,
        fomoCardSecondaryCTAClicked,
        rescheduleScreenActions,
        handleShowRescheduleModal,
        handleShowEditModal,
        handleShowCancelCard,
        handleCancelAppointment,
        onCTAClick,
        onCommsEditApptActionClicked,
        onCommsRescheduleConfirmed,
        onBackClick,
      },
    ]
  }, [
    cancelCardData,
    commsFomoModal?.config,
    commsFomoModal?.isVisible,
    dummyActionCards,
    fomoCardPrimaryCTAClicked,
    fomoCardSecondaryCTAClicked,
    handleCancelAppointment,
    handleShowCancelCard,
    handleShowEditModal,
    handleShowRescheduleModal,
    isLoading,
    onBackClick,
    onCTACardClick,
    onCTAClick,
    onCommsEditApptActionClicked,
    onCommsRescheduleConfirmed,
    rescheduleScreenActions,
    rescheduleScreenState,
    showCancelCard,
    showEditModal,
    showRCLHeader,
    showRescheduleModal,
    userContent?.CONSULT_HOME,
    category,
  ])

  return commHomeProps
}

export {useCommsHomePage}
