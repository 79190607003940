import React, {useCallback} from 'react'
import {checkIsApp} from 'src/utils/checkIsApp'

function useBackNavigation() {
  const isApp = checkIsApp()

  const handleBackClick = useCallback(() => {
    if (isApp) {
      window?.ReactNativeWebView?.postMessage(
        JSON.stringify({
          actions: 'NAVIGATE_BACK',
        })
      )
      return
    }
    localStorage.setItem('previousUrl', window.location.href)
    window?.history?.back()
    return
  }, [isApp])

  return {handleBackClick}
}

export {useBackNavigation}
