import {useCallback, useEffect, useState} from 'react'
import {useRouter} from 'next/router'
import isEmpty from 'lodash/isEmpty'

import {checkIsApp} from 'src/utils/checkIsApp'
import {useGenericCta} from '../useGenericCta'

function useWebViewHeader(props: IUseWebViewHeaderProps = {}) {
  const {
    fallbackText = '',
    visibilityMapping,
    appNavAction = 'NAVIGATE_BACK',
    from = '',
  } = props

  const [showHeader, setShowHeader] = useState({
    showHeader: false,
    title: '',
  })
  const {asPath, back} = useRouter()
  const {handleCta} = useGenericCta()

  const onBackClick = useCallback(() => {
    if (typeof window === 'undefined') {
      return
    }

    if (window.history.state && window.history.state.idx === 0) {
      //@ts-ignore
      window?.ReactNativeWebView?.postMessage(
        JSON.stringify({
          actions: appNavAction,
        })
      )
    } else {
      if (from === 'ThankYouPage') {
        handleCta({action: 'ROUTER_REPLACE', link: '/'})
        return
      }
      back()
    }
  }, [appNavAction, back, from, handleCta])

  /* On Route Change calculate if the webview header should be shown */
  useEffect(() => {
    const isApp = checkIsApp()

    if (isEmpty(visibilityMapping) || !isApp) {
      return
    }

    let mappingFoundCount = 0

    for (const i in visibilityMapping) {
      switch (true) {
        case i === '/' &&
          (asPath.split('?')[0] === '/' || asPath.split('#')[0] === '/'): {
          setShowHeader({
            showHeader: visibilityMapping[i].visible,
            title: visibilityMapping[i].title || fallbackText,
          })
        }

        case asPath !== '' && asPath.includes(i): {
          mappingFoundCount += 1

          setShowHeader({
            showHeader: visibilityMapping[i].visible,
            title: visibilityMapping[i].title,
          })

          break
        }

        case mappingFoundCount === 0: {
          setShowHeader({
            showHeader: false,
            title: '',
          })
          break
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asPath, visibilityMapping])

  return {showHeader, onBackClick}
}

export {useWebViewHeader}
