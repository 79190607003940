import {SCREEN_PATH_NAMES} from 'src/utils/constants/screenPathNames'

export const PAGES_WITH_OUT_HEADER = [
  SCREEN_PATH_NAMES.SCHEDULE_APPOINTMENT,
  SCREEN_PATH_NAMES.IMAGE_UPLOAD,
  SCREEN_PATH_NAMES.COMMS_CONSULT_HOME,
  SCREEN_PATH_NAMES.COMMS_DA,
  SCREEN_PATH_NAMES.COMMS_CONSULT_HOME_V2,
  SCREEN_PATH_NAMES.MINI_ASSESSMENT,
  SCREEN_PATH_NAMES.REPORTING_EHR,
  SCREEN_PATH_NAMES.ALL_REVIEWS_DP_WITH_TWO_PRODUCTSLUGS,
  SCREEN_PATH_NAMES.ALL_REVIEWS_DP_WITH_ONE_PRODUCTSLUGS,
  SCREEN_PATH_NAMES.ALL_REVIEWS_WITH_URLKEYSLUG,
  SCREEN_PATH_NAMES.ALL_QUESTIONS_DP_WITH_TWO_PRODUCTSLUGS,
  SCREEN_PATH_NAMES.ALL_QUESTIONS_DP_WITH_ONE_PRODUCTSLUGS,
  SCREEN_PATH_NAMES.ALL_QUESTIONS_WITH_URLKEYSLUG,
  SCREEN_PATH_NAMES.UPDATE_PATIENT_DETAILS,
  SCREEN_PATH_NAMES.ROOK_CONNECT,
  SCREEN_PATH_NAMES.ROOK_CONNECT_V2,
  SCREEN_PATH_NAMES.ROOK_CHOOSE_GOALS,
  SCREEN_PATH_NAMES.ROOK_MODIFY_GOALS,
  SCREEN_PATH_NAMES.ROOK_HEALTH_PAGE,
  SCREEN_PATH_NAMES.ROOK_CONNECTED,
  SCREEN_PATH_NAMES.ROOK_HEALTH_DATA,
  SCREEN_PATH_NAMES.REPORT_SECOND_OPINION,
  SCREEN_PATH_NAMES.BOOK_CONSULTATION,
  SCREEN_PATH_NAMES.CLEAR_CONSULTATION_DUES,
  SCREEN_PATH_NAMES.NOTIFY_ME_SLOT_ACTIONS,
  SCREEN_PATH_NAMES.NOTIFY_ME_SLOT_BOOKING,
  SCREEN_PATH_NAMES.NOTIFY_ME_COMPLETE,
  SCREEN_PATH_NAMES.CHECKOUT,
  SCREEN_PATH_NAMES.SUBMIT_REVIEW_DP_WITH_ONE_PRODUCTSLUGS,
  SCREEN_PATH_NAMES.CONSULTATION_SUMMARY,
]
export const PAGES_WITH_OUT_FOOTER = [
  SCREEN_PATH_NAMES.CHECKOUT,
  SCREEN_PATH_NAMES.CART,
  SCREEN_PATH_NAMES.THANK_YOU,
  SCREEN_PATH_NAMES.WELLNESS_ASSESSMENT,
  SCREEN_PATH_NAMES.SCHEDULE_APPOINTMENT,
  SCREEN_PATH_NAMES.SCHEDULE_APPOINTMENT_DEMO,
  SCREEN_PATH_NAMES.APPOINTMENT_CONFIRM,
  SCREEN_PATH_NAMES.WELLNESS_ASSESSMENT_FORM,
  SCREEN_PATH_NAMES.IMAGE_UPLOAD,
  SCREEN_PATH_NAMES.CONFIRMATION_SCREEN,
  SCREEN_PATH_NAMES.COMMS_CONSULT_HOME,
  SCREEN_PATH_NAMES.COMMS_DA,
  SCREEN_PATH_NAMES.COMMS_CONSULT_HOME_V2,
  SCREEN_PATH_NAMES.MINI_ASSESSMENT,
  SCREEN_PATH_NAMES.REPORTING_EHR,
  SCREEN_PATH_NAMES.ALL_REVIEWS_DP_WITH_TWO_PRODUCTSLUGS,
  SCREEN_PATH_NAMES.ALL_REVIEWS_DP_WITH_ONE_PRODUCTSLUGS,
  SCREEN_PATH_NAMES.ALL_REVIEWS_WITH_URLKEYSLUG,
  SCREEN_PATH_NAMES.ALL_QUESTIONS_DP_WITH_TWO_PRODUCTSLUGS,
  SCREEN_PATH_NAMES.ALL_QUESTIONS_DP_WITH_ONE_PRODUCTSLUGS,
  SCREEN_PATH_NAMES.ALL_QUESTIONS_WITH_URLKEYSLUG,
  SCREEN_PATH_NAMES.UPDATE_PATIENT_DETAILS,
  SCREEN_PATH_NAMES.LOGIN,
  SCREEN_PATH_NAMES.MINI_ASSESSMENT_WITH_RECOMMENDATIONS,
  SCREEN_PATH_NAMES.ROOK_CONNECT,
  SCREEN_PATH_NAMES.ROOK_CONNECT_V2,
  SCREEN_PATH_NAMES.ROOK_CHOOSE_GOALS,
  SCREEN_PATH_NAMES.ROOK_MODIFY_GOALS,
  SCREEN_PATH_NAMES.ROOK_HEALTH_PAGE,
  SCREEN_PATH_NAMES.ROOK_CONNECTED,
  SCREEN_PATH_NAMES.ROOK_HEALTH_DATA,
  SCREEN_PATH_NAMES.SELF_ASSESS,
  SCREEN_PATH_NAMES.LANGUAGE_PREFERENCE,
  SCREEN_PATH_NAMES.SELF_ASSESSMENT_REPORT,
  SCREEN_PATH_NAMES.WELLNESS_ASSESSMENT_REPORT,
  SCREEN_PATH_NAMES.REPORT_SECOND_OPINION,
  SCREEN_PATH_NAMES.BOOK_CONSULTATION,
  SCREEN_PATH_NAMES.DOCTOR,
  SCREEN_PATH_NAMES.EXPERTS,
  SCREEN_PATH_NAMES.EXPERTS_WITH_CATEGORY,
  SCREEN_PATH_NAMES.CONSULTATION_PAYMENT_SUMMARY,
  SCREEN_PATH_NAMES.CLEAR_CONSULTATION_DUES,
  SCREEN_PATH_NAMES.NOTIFY_ME_SLOT_ACTIONS,
  SCREEN_PATH_NAMES.NOTIFY_ME_SLOT_BOOKING,
  SCREEN_PATH_NAMES.NOTIFY_ME_COMPLETE,
  SCREEN_PATH_NAMES.WALLET_RECHARGE_REORDER,
  SCREEN_PATH_NAMES.USER_FEEDBACK,
  SCREEN_PATH_NAMES.SUBMIT_REVIEW_DP_WITH_ONE_PRODUCTSLUGS,
  SCREEN_PATH_NAMES.CONSULTATION_SUMMARY,
]

export const PAGES_WITH_OUT_SIDE_CART = [
  SCREEN_PATH_NAMES.CHECKOUT,
  SCREEN_PATH_NAMES.CART,
  SCREEN_PATH_NAMES.THANK_YOU,
  SCREEN_PATH_NAMES.ALL_REVIEWS_DP_WITH_TWO_PRODUCTSLUGS,
  SCREEN_PATH_NAMES.ALL_REVIEWS_DP_WITH_ONE_PRODUCTSLUGS,
  SCREEN_PATH_NAMES.ALL_REVIEWS_WITH_URLKEYSLUG,
  SCREEN_PATH_NAMES.ALL_QUESTIONS_DP_WITH_TWO_PRODUCTSLUGS,
  SCREEN_PATH_NAMES.ALL_QUESTIONS_DP_WITH_ONE_PRODUCTSLUGS,
  SCREEN_PATH_NAMES.ALL_QUESTIONS_WITH_URLKEYSLUG,
  SCREEN_PATH_NAMES.SUBMIT_REVIEW_DP_WITH_ONE_PRODUCTSLUGS,
]
