import React, {useEffect, useMemo, useCallback} from 'react'
import noop from 'lodash/noop'

import {ProductPreviewModalContainer} from './ProductPreviewModal.styles'
import Modal from '../../../ComponentsV2/common/Modal'
import Responsive from '../../../ComponentsV2/HOC/Responsive'
import RatingStars from '../../../ComponentsV2/common/RatingStars/ratingStars'
import HeadingComponent from '../../../ComponentsV2/common/HeadingComponent'
import Description from '../../../ComponentsV2/Screens/LJ-PDP/Description'
import LoadingState from '../../../ComponentsV2/common/LoadingState'
import {useInternationalizationContext} from '../../../ComponentsV2/Context/InternationalizationContext'

function ProductPreviewModal(props) {
  const {isMobile = false} = props
  const {
    id,
    productImageUrl = '',
    concern = '',
    headingLevel = 1,
    name = '',
    productFor = '',
    discountedPrice = null,
    discountText = '',
    actualPrice = null,
    reviews = '',
    rating = '',
    recommendation = '',
    unitsSold = null,
    howToUse = {},
    showModal = true,
    isLoading = false,
    urlKey = '',
  } = props.state || {}

  const {
    onAllDetailsClick = noop,
    onClose = noop,
    fetchPdpData = noop,
  } = props.actions || {}

  const {currency} = useInternationalizationContext()

  const [starHeight, starWidth] = useMemo(() => {
    return isMobile ? [12, 12] : [21, 22]
  }, [isMobile])

  const handleAllDetailsClick = useCallback(() => {
    onAllDetailsClick({link: urlKey, id, name})
  }, [onAllDetailsClick, urlKey, id, name])

  useEffect(() => {
    if (urlKey) {
      fetchPdpData(urlKey)
    }
  }, [fetchPdpData, urlKey])

  return (
    <ProductPreviewModalContainer>
      <Modal
        showModal={showModal}
        onClose={onClose}
        additionalModalClass="modal"
        additionalContentClass="modal-content"
      >
        <div className="product-preview-wrapper">
          {isLoading ? (
            <div className="loading-container">
              <LoadingState numberOfCards={1} cardHeight={100} />
              <LoadingState numberOfCards={1} cardHeight={25} />
              <LoadingState numberOfCards={1} cardHeight={25} />
              <LoadingState numberOfCards={1} cardHeight={50} />
            </div>
          ) : (
            <>
              <div className="product-preview-modal__product-container">
                <div className="product-preview-modal__product-header">
                  <img
                    className="product-preview-modal__product-image-container"
                    alt="productImage"
                    src={productImageUrl}
                  />

                  <div className="product-preview-modal__product-info-container">
                    <HeadingComponent
                      headingLevel={headingLevel}
                      customClassName="main-title"
                      label={name}
                    />
                    {productFor && (
                      <div className="product-preview-modal__product-for">
                        For: <span>{productFor}</span>
                      </div>
                    )}
                    <div className="price-section">
                      <span className="price-section__rupee">{currency}</span>
                      <span className="price-section__discounted_price">
                        {discountedPrice || actualPrice}
                      </span>
                      {!!discountedPrice && (
                        <span className="price-section__actual_price">{`${currency}${actualPrice}`}</span>
                      )}
                      {discountText && (
                        <span className="price-section__discount_text">
                          {discountText}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="rating-section">
                  {parseFloat(reviews) > 0 && (
                    <>
                      <div className="rating-section__rating_container">
                        <span className="rating">{rating}</span>
                        <RatingStars
                          rating={rating}
                          height={starHeight}
                          width={starWidth}
                          blendWithBackground
                        />
                      </div>
                      <div className="rating-section__divider"></div>
                      <span className="rating-section__review">
                        {reviews} Reviewed
                      </span>
                      {(recommendation || !!unitsSold) && (
                        <div className="rating-section__divider"></div>
                      )}
                    </>
                  )}
                  {!!unitsSold && (
                    <span>{unitsSold.toLocaleString('en-IN')} Units Sold</span>
                  )}
                  {recommendation && !unitsSold && (
                    <span>{recommendation} Recommended</span>
                  )}
                </div>
                {concern && (
                  <div className="product-preview-modal__product-concern">
                    {concern}
                  </div>
                )}
              </div>
              <Description
                data={{...howToUse, allDetails: {handleAllDetailsClick}}}
              />
            </>
          )}
        </div>
      </Modal>
    </ProductPreviewModalContainer>
  )
}

export default Responsive(ProductPreviewModal)
