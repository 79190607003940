import noop from 'lodash/noop'
import {useCallback} from 'react'
import {checkIsApp} from 'src/utils/checkIsApp'
import {useGenericCta} from '..'
import {useProductNavigation} from '../product/useProductNavigation'
import {IUseCta, CTA_ACTION} from './useGenericCtaV2.interface'
import {useRouter} from 'next/router'
import {useUpdateItemToCart} from '../updateCart/useUpdateItemToCart'
import {useConfigStore} from '@mosaic-wellness/redux-action-library'
import {analyticsPush, analyticsTriggerBypassProcessor} from 'src/analytics'
import { GenericAction } from '@mosaic-wellness/fe-types'

export const useGenericCtaV2 = () => {
  const {addToCart} = useUpdateItemToCart()
  const {navigateToProduct} = useProductNavigation()
  const {handleCta = noop} = useGenericCta()
  const router = useRouter()
  const isApp = checkIsApp()
  const {
    environment: {toast},
  } = useConfigStore()
  const handleCtaV2 = useCallback(
    async (cta: IUseCta) => {
      const {action, data} = cta
      switch (action) {
        case CTA_ACTION.ADD_TO_CART: {
          const {sku} = data || {}

          if (isApp) {
            //@ts-ignore
            window?.ReactNativeWebView?.postMessage(
              JSON.stringify({
                actions: action,
                actionData: {product: {sku}},
              })
            )
            return
          }
          addToCart({sku})
          break
        }

        case CTA_ACTION.OPEN_PDP: {
          const {slugUrl, urlKey, replaceNav = false} = data

          if (isApp) {
            //@ts-ignore
            window?.ReactNativeWebView?.postMessage(
              JSON.stringify({
                actions: action,
                actionData: {productKey: urlKey, replaceNav},
              })
            )
            return
          }

          navigateToProduct({slugUrl, urlKey})
          break
        }

        case CTA_ACTION.APP_NAV: {
          const {webNavigationData, appNavigationData} = data

          if (isApp) {
            //@ts-ignore
            window?.ReactNativeWebView?.postMessage(
              JSON.stringify({
                actions: action,
                actionData: appNavigationData,
              })
            )
            return
          }
          handleCta(webNavigationData)
          break
        }

        case CTA_ACTION.SPA_LINK: {
          handleCta({action, ...data})
          break
        }

        case CTA_ACTION.BOTTOM_TAB: {
          const {webNavigationData, appNavigationData} = data

          if (isApp) {
            //@ts-ignore
            window?.ReactNativeWebView?.postMessage(
              JSON.stringify({
                actions: action,
                actionData: appNavigationData,
              })
            )
            return
          }
          handleCta(webNavigationData)
          break
        }

        case CTA_ACTION.GO_BACK: {
          if (isApp) {
            // @ts-ignore
            window?.ReactNativeWebView?.postMessage(
              JSON.stringify({
                actions: action,
              })
            )
          } else {
            router.back()
          }
          break
        }
        case CTA_ACTION.CLEAR_APPOINTMENT_DETAILS_CONTEXT: {
          if (isApp) {
            //@ts-ignore
            window?.ReactNativeWebView?.postMessage(
              JSON.stringify({
                actions: action,
              })
            )
            return
          }
          break
        }

        case CTA_ACTION.AUTHORIZED_APP_NAV: {
          const {webNavigationData, appNavigationData} = data
          if (isApp) {
            //@ts-ignore
            window?.ReactNativeWebView?.postMessage(
              JSON.stringify({
                actions: action,
                actionData: appNavigationData,
              })
            )
            return
          } else {
            handleCta(webNavigationData)
          }
        }
        case CTA_ACTION.TRIGGER_EVENTS_FOR_WEB: {
          const {event, eventData} = data
          if (isApp) {
            window?.ReactNativeWebView?.postMessage(
              JSON.stringify({
                actions: 'TRIGGER_EVENTS_WEB_VIEW',
                actionData: {
                  event: event,
                  data: eventData,
                },
              })
            )
          } else {
            analyticsTriggerBypassProcessor(event, eventData)
          }
          return
        }
        case CTA_ACTION.UPDATE_DATA_LAYER: {
          const {eventData} = data
          analyticsPush(eventData)
          return
        }
        case CTA_ACTION.SHARE: {
          const {url} = data
          if (!url) {
            toast('warning', 'Invalid URL!')
            return
          }
          if (window.navigator.share) {
            navigator.share(data)
          } else {
            if (
              navigator &&
              navigator.clipboard &&
              navigator.clipboard.writeText
            ) {
              await navigator.clipboard.writeText(url)
              toast('success', 'Link Copied to Clipboard')
            }
          }
        }
      }
    },
    [addToCart, handleCta, isApp, navigateToProduct, router]
  )

  const handleCtaActions = useCallback(
    (actions: IUseCta[]) => {
      if (Array.isArray(actions) && actions.length > 0) {
        actions.forEach((action) => {
          handleCtaV2(action)
        })
      }
    },
    [handleCtaV2]
  )

  const handleActionsFromFEMono = useCallback(
    (action: GenericAction) => {
      const {actionName, params} = action
      handleCtaV2({
        action: actionName,
        data: params,
      })
    },
    [handleCtaV2]
  )

  return {
    handleCtaV2,
    handleCtaActions,
    handleActionsFromFEMono,
  }
}
