export const SCREEN_PATH_NAMES = {
  CART: '/cart',
  CHECKOUT: '/checkout-v2',
  THANK_YOU: '/status-v2/[orderId]',
  HOME: '/',
  MY_PROFILE: '/my-profile',
  WELLNESS_ASSESSMENT: '/wellness-assessment',
  WELLNESS_ASSESSMENT_REPORT: '/wellness-assessment-report',
  SCHEDULE_APPOINTMENT: '/schedule-appointment',
  SCHEDULE_APPOINTMENT_DEMO: '/scheduleappointment',
  APPOINTMENT_CONFIRM: '/appointmentConfirmed',
  WELLNESS_ASSESSMENT_FORM: '/wellness-assessment-form',
  IMAGE_UPLOAD: '/upload-image',
  CONFIRMATION_SCREEN: '/confirmation-screen',
  COMMS_CONSULT_HOME: '/comms-consult-home',
  COMMS_DA: '/book-appointment',
  COMMS_CONSULT_HOME_V2: '/consult-home',
  MINI_ASSESSMENT: '/mini-assessment/[category]',
  REPORTING_EHR: '/reporting-ehr',
  ALL_REVIEWS_DP_WITH_TWO_PRODUCTSLUGS:
    '/dp/[urlKey]/[productUrlKey]/all-reviews',
  ALL_REVIEWS_DP_WITH_ONE_PRODUCTSLUGS: '/dp/[urlKey]/all-reviews',
  ALL_REVIEWS_WITH_URLKEYSLUG: '/product/[urlKey]/all-reviews',
  SUBMIT_REVIEW_DP_WITH_ONE_PRODUCTSLUGS: '/dp/[urlKey]/submit-review',
  ALL_QUESTIONS_DP_WITH_TWO_PRODUCTSLUGS:
    '/dp/[urlKey]/[productUrlKey]/all-questions',
  ALL_QUESTIONS_DP_WITH_ONE_PRODUCTSLUGS: '/dp/[urlKey]/all-questions',
  ALL_QUESTIONS_WITH_URLKEYSLUG: '/product/[urlKey]/all-questions',
  UPDATE_PATIENT_DETAILS: '/update-patient-details',
  LOGIN: '/login',
  MINI_ASSESSMENT_WITH_RECOMMENDATIONS: '/mini-assessment-v2/[category]',
  ROOK_CONNECT: '/rook-connect',
  ROOK_CONNECT_V2: '/rook-connect-v2',
  ROOK_CHOOSE_GOALS: '/rook-choose-goals',
  ROOK_MODIFY_GOALS: '/rook-modify-goals',
  ROOK_HEALTH_PAGE: '/rook-healthpage',
  ROOK_CONNECTED: '/rook-connected/[[...slugs]]',
  ROOK_HEALTH_DATA: '/rook-health-data',
  SELF_ASSESS: '/self-assess',
  SELF_ASSESSMENT_REPORT: '/self-assessment-report',
  LANGUAGE_PREFERENCE: '/language-preference',
  REPORT_SECOND_OPINION: '/wellness-assessment-report-second-opinion',
  BOOK_CONSULTATION: '/book-consultation',
  DOCTOR: '/doctor/[id]',
  EXPERTS: '/experts',
  EXPERTS_WITH_CATEGORY: '/experts/[category]',
  CONSULTATION_PAYMENT_SUMMARY: '/consultation-payment-summary',
  CLEAR_CONSULTATION_DUES: '/clear-consultation-dues',
  NOTIFY_ME_SLOT_ACTIONS: '/notify-me/[doctorId]',
  NOTIFY_ME_SLOT_BOOKING: '/notify-me/[doctorId]/book-slot',
  NOTIFY_ME_COMPLETE: '/notify-me/[doctorId]/complete',
  WALLET_RECHARGE_REORDER: '/wallet-recharge-reorder',
  USER_FEEDBACK: '/user-feedback',
  CONSULTATION_SUMMARY: '/consultation-summary/[id]',
}
