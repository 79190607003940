import {useCallback, useEffect, useRef, useState} from 'react'
import {
  useGetAppointmentDetails,
  useConfigStore,
  useCommsActiveRoute,
} from '@mosaic-wellness/redux-action-library'
import isEmpty from 'lodash/isEmpty'
import {checkIsApp} from 'src/utils/checkIsApp'
import noop from 'lodash/noop'

import {useDaFomoCard, useDaImageUpload} from '../../directAssessment'
import {useGenericCta} from '../../useGenericCta'
import {IUseImageUploadPageReturnType} from './useImageUploadPage.interface'
import {useImageUploadTracking} from 'src/hooks/tracking/useImageUploadTracking'
import {useRedirection} from 'src/hooks'
import {isNativeConsultVersion} from 'src/utils/isNativeConsultVersion'

function useImageUploadPage(): IUseImageUploadPageReturnType {
  const [imageUploadState, imageUploadActions] = useDaImageUpload()
  const [{data}] = useCommsActiveRoute()
  const [
    {showDismissModal},
    {onFomoCardSecondaryCtaClick, toggleDismissModal},
  ] = useDaFomoCard()
  const {handleCta} = useGenericCta()
  const [{appointmentDetails}, {getAppointmentDetails}] =
    useGetAppointmentDetails()
  const {
    pushAppointmentDataToDataLayer,
    onImageUploadViewed,
    onImageSizeExceeded,
    onImageUploadSuccess,
    onImageFlowExit,
    onImageUploadInitiated,
    onWhatToUploadClicked,
  } = useImageUploadTracking()
  const {
    environment: {toast},
  } = useConfigStore()
  const isApp = checkIsApp()
  const [
    {currentConsultVersion = '', currentSource = ''},
    {handleRedirection = noop},
  ] = useRedirection()

  const [scheduleToken, setScheduleToken] = useState<string>('')

  const exitInitiatedFrom = useRef('')

  const primaryRedirect = useCallback(() => {
    if (isApp && (currentConsultVersion || currentSource)) {
      handleRedirection({
        action: 'APP_NAV',
        link: {
          appNavigationData: {
            path: 'Consult',
          },
        },
        version: currentConsultVersion,
        source: currentSource,
      })
      return
    }

    if (isApp) {
      handleCta({action: 'SPA_LINK', link: data?.link})
      return
    }
    handleCta({
      action: 'SPA_LINK',
      link: `confirmation-screen?schedule_token=${scheduleToken}`,
    })
  }, [
    currentConsultVersion,
    currentSource,
    data?.link,
    handleCta,
    handleRedirection,
    isApp,
    scheduleToken,
  ])

  const handleImageSizeExceeded = useCallback(
    ({message, size}: {message: string; size: string}) => {
      onImageSizeExceeded({size})
      toast(
        'warning',
        message || 'Image size too large, kindly upload the image below 10 MB.'
      )
    },
    [onImageSizeExceeded, toast]
  )

  const onDismissClick = useCallback(() => {
    exitInitiatedFrom.current = 'Cross'
    toggleDismissModal()
  }, [toggleDismissModal])

  const onBackClick = useCallback(() => {
    exitInitiatedFrom.current = 'Back'
    toggleDismissModal()
  }, [toggleDismissModal])

  const onSecondaryClick = useCallback(() => {
    if (
      isNativeConsultVersion({currentConsultVersion}) ||
      (!isEmpty(currentSource) && isApp)
    ) {
      handleRedirection({
        action: 'APP_NAV',
        link: {
          appNavigationData: {
            path: 'Consult',
          },
        },
        version: currentConsultVersion,
        source: currentSource,
      })
      return
    }
    onImageFlowExit({exitedFrom: exitInitiatedFrom.current})

    if (exitInitiatedFrom.current === 'Back') {
      primaryRedirect()
      return
    }

    onFomoCardSecondaryCtaClick()
  }, [
    currentConsultVersion,
    currentSource,
    handleRedirection,
    isApp,
    onFomoCardSecondaryCtaClick,
    onImageFlowExit,
    primaryRedirect,
  ])

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const scheduleToken = urlParams.get('schedule_token')

    if (scheduleToken) {
      setScheduleToken(scheduleToken)
    }
  }, [])

  useEffect(() => {
    if (scheduleToken) {
      getAppointmentDetails({scheduleToken})
    }
  }, [getAppointmentDetails, scheduleToken])

  useEffect(() => {
    if (!isEmpty(appointmentDetails)) {
      pushAppointmentDataToDataLayer({
        category: appointmentDetails?.categories,
        appointmentId: appointmentDetails?.appointmentId,
        appointmentType: appointmentDetails?.appointmentType,
      })

      onImageUploadViewed({
        category: appointmentDetails?.categories,
        appointmentId: appointmentDetails?.appointmentId,
        appointmentType: appointmentDetails?.appointmentType,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentDetails])

  return [
    {
      ...imageUploadState,
      showDismissModal,
      scheduleToken,
      category: Array.isArray(appointmentDetails?.categories)
        ? appointmentDetails?.categories[0]
        : '',
    },
    {
      ...imageUploadActions,
      trackImageUpload: onImageUploadSuccess,
      onImageSizeExceeded: handleImageSizeExceeded,
      onDismissClick,
      onBackClick,
      onSecondaryClick,
      toggleDismissModal,
      onImageUploadInitiated,
      onWhatToUploadClicked,
      primaryRedirect,
    },
  ]
}

export {useImageUploadPage}
