import LJ_THEME from 'src/components/shared/src/lj.theme'
import BW_THEME from 'src/components/shared/src/bw.theme'
import MM_THEME from 'src/components/shared/src/mm.theme'
import LJ_AE_THEME from 'src/components/shared/src/lj-ae.theme'
import LJ_SA_THEME from 'src/components/shared/src/lj-sa.theme'

import {GlobalStyle} from 'layout/Basic'
import {Toaster} from 'react-hot-toast'
import React, {useCallback, useMemo, useRef, useState} from 'react'
import UTMTracking from 'src/components/UTMTracking'
import {createConfiguration} from 'src/action-library/actionLibraryConfig'
import {ActionLibrary} from '@mosaic-wellness/redux-action-library'
import type {AppProps} from 'next/app'
import DeviceInfo from 'src/components/shared/src/ComponentsV2/Context/DeviceInfo'
import InternationalizationContextProvider from 'src/components/shared/src/ComponentsV2/Context/InternationalizationContext'
import {useRouter} from 'next/router'

import {useHeaderAndFooter} from 'src/hooks/useHeaderAndFooter'
import PageCallout from 'src/components/utility/Callout'
import {useInterceptNextDataHref} from 'src/hooks/useInterceptNextDataHref'
import {useBoot} from 'src/hooks/boot/useBoot'
import dynamic from 'next/dynamic'
import {useSetPrimaryUtmSource} from 'src/hooks'
import SessionHandling from 'src/components/Sessionhandling'
import PageLoading from 'src/components/PageLoading'
import AppConfigProvider from 'src/context/AppConfigContext'
import Head from 'next/head'
import {checkIsApp} from 'src/utils/checkIsApp'
import FloatingIslandNudgesBar from 'src/components/FloatingIslandNudgesBar'
import CartLoadingContextProviderWithProps from 'src/context/CartLoadingContextProviderWithProps'
import WebViewHeaderWrapper from 'src/components/WebViewHeaderWrapper'
import FloatingSelfDiagnosisToastWrapper from 'src/components/FloatingSelfDiagnosisToastWrapper'
import {generateTheme} from 'src/components/shared/src/dls/theme/generateTheme'
import {LocalisationProvider} from 'src/components/shared/src/ComponentsV2/Context/Localisation/Localisation'
import {CONSTANTS} from 'src/components/shared/src/ComponentsV2/Context/Localisation/constants'
import LoginContainer from 'src/containers/Login'
import Boot from 'src/components/Boot'
import ATCBottomSheetContainer from 'src/containers/ATCBottomSheetContainer'

const CUSTOM_ANALYTICS_ENABLED = process.env.NEXT_PUBLIC_CUSTOM_ANALYTICS || ''
const COUNTRY = process.env.NEXT_PUBLIC_COUNTRY || 'IN'

const Analytics = dynamic(
  () =>
    import(/* webpackChunkName: "analytics" */ '../src/components/Analytics'),
  {
    ssr: false,
  }
)

const SearchComponent = dynamic(
  () =>
    import(
      /* webpackChunkName: "search" */ '../src/components/Search/SearchComponent'
    ),
  {
    ssr: false,
  }
)

const SideCart = dynamic(
  () => import(/* webpackChunkName: "sidecart" */ '../src/components/SideCart'),
  {
    ssr: false,
  }
)
const Footer = dynamic(
  () => import(/* webpackChunkName: "footer" */ '../src/components/Footer'),
  {
    ssr: true,
  }
)

const Navbar = dynamic(
  () => import(/* webpackChunkName: "navbar" */ '../src/components/Nav'),
  {
    ssr: true,
  }
)

const BRAND = process.env.NEXT_PUBLIC_BRAND || 'lj'
function MyApp({Component, pageProps, router: ogRouter, ...rest}: AppProps) {
  const {pageData, pageCallout, isRept} = pageProps || {}
  const {checkoutConfig, headerAndFooterData, textCopies} = pageData || {}
  const headerAndFooterDataRef = useRef(headerAndFooterData)
  const checkoutConfigRef = useRef(checkoutConfig)
  const textCopiesRef = useRef(textCopies)
  //@ts-expect-error
  const {device, err} = rest
  const {
    navBarData = {},
    footerData = {},
    wellnessAssessment = {},
    userFeedBack = {},
    search = {},
    pagesThatNeedLogin: protectedRouteData,
    consultationNudgeData = {},
    favicon = {},
    flags = {},
    appConfig = {},
    webviewHeaderConfig = {},
    loginData = {},
  } = headerAndFooterDataRef?.current || {}

  const {href: faviconLink} = favicon || {}

  const router = useRouter()

  const isApp = pageProps?.isApp || checkIsApp()

  // ! experimental code
  useInterceptNextDataHref({
    router: ogRouter,
  })
  useBoot()
  const {showHeader, showFooter, showSideCart} = useHeaderAndFooter()

  const configuration = useMemo(
    () => createConfiguration(router, checkoutConfigRef?.current),
    [router]
  )

  const BRAND_THEME = useMemo(() => {
    switch (BRAND) {
      case 'mm':
        return MM_THEME
      case 'bw':
        return BW_THEME
      case 'lj-ae':
        return LJ_AE_THEME
      case 'lj-sa':
        return LJ_SA_THEME
      default:
        return LJ_THEME
    }
  }, [])

  const theme = generateTheme(BRAND)

  const [showSearchModal, setShowSearchModal] = useState(false)

  useSetPrimaryUtmSource()

  const toggleSearchModal = useCallback(() => {
    setShowSearchModal((showSearchModal) => !showSearchModal)
  }, [])

  return (
    <>
      <LocalisationProvider value={{...CONSTANTS, ...textCopiesRef.current}}>
        <Head>
          <link rel="icon" href={faviconLink} type="image/x-icon"></link>
        </Head>
        <BRAND_THEME theme={theme} />
        <GlobalStyle />
        <ActionLibrary config={configuration} isForWeb>
          <AppConfigProvider value={appConfig}>
            <Toaster containerClassName="non-clickable-toaster" />
            <Boot />
            <InternationalizationContextProvider country={COUNTRY}>
              <DeviceInfo presetDeviceInfo={{device}}>
                {!isApp && (
                  <>
                    <PageCallout
                      pageCallout={pageCallout}
                      appConfig={appConfig}
                      isRept={isRept}
                    />
                    <Navbar
                      navState={navBarData}
                      showHeader={showHeader}
                      showSearchModal={showSearchModal}
                      handleShowSearchModal={toggleSearchModal}
                      consultationNudgeData={consultationNudgeData}
                    />
                  </>
                )}
                {checkoutConfigRef?.current?.sideCart && showSideCart && (
                  <SideCart />
                )}

                <LoginContainer loginData={loginData} />
                <FloatingIslandNudgesBar />
                <FloatingSelfDiagnosisToastWrapper />
                <WebViewHeaderWrapper config={webviewHeaderConfig} />
                {showSearchModal && (
                  <SearchComponent
                    searchBarData={search}
                    handleHideSearchClicked={toggleSearchModal}
                  />
                )}
                <div className="nav_bar_partition"></div>
                <PageLoading>
                  <CartLoadingContextProviderWithProps>
                    <Component
                      err={err} // this supports any error that we need handle
                      {...pageProps}
                      key={router.asPath}
                      wellnessAssessment={wellnessAssessment}
                      userFeedBack={userFeedBack}
                      protectedRouteData={protectedRouteData}
                      flags={flags}
                    />
                    <ATCBottomSheetContainer />
                  </CartLoadingContextProviderWithProps>
                </PageLoading>
                {!isApp && showFooter && <Footer footerData={footerData} />}
                {CUSTOM_ANALYTICS_ENABLED && <Analytics />}
                <SessionHandling />
              </DeviceInfo>
            </InternationalizationContextProvider>
            <UTMTracking />
          </AppConfigProvider>
        </ActionLibrary>
      </LocalisationProvider>
    </>
  )
}

export default MyApp
