import {useEffect} from 'react'
import {useCartStore} from '@mosaic-wellness/redux-action-library'

function useViewPrescriptionCart() {
  const {applyDiscount} = useCartStore()

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const apply_discount = urlParams.get('apply_discount')
    const prescription = urlParams.get('prescription')

    if (prescription) {
      window.sessionStorage.setItem('prescription', prescription as string)
    }

    if (apply_discount) {
      applyDiscount('coupon', true, apply_discount as string)
    }
  }, [])
}

export {useViewPrescriptionCart}
