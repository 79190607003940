import {HeightConverter} from './ChildDetails.interface'
import bmiJson from './bmIAP.json'

// * * Convert height
export const convertToCms = (
  heightFeet: HeightConverter,
  heightInches: HeightConverter
) => {
  const feet = +heightFeet
  const inches = +heightInches

  if (heightFeet !== undefined && heightInches !== undefined) {
    // Convert feet and inches to centimeters
    const totalInches = feet * 12 + inches
    const heightCms = totalInches * 2.54
    return heightCms
  } else {
    // Invalid input
    return null
  }
}

export function getBMIResult(childData) {
  const {
    childGender = '',
    dob = '',
    currentHeight = '',
    currentWeight = '',
  } = childData || {}

  const {year, month} = calculateAge(dob)

  if (currentHeight && currentWeight && year >= 5 && year <= 18) {
    let height = currentHeight / 100
    let weightUnit = Number(currentWeight)

    let bmi = weightUnit / Math.pow(height, 2)
    let BMI = parseFloat(bmi).toFixed(2)

    const gender = childGender.toLowerCase()

    let ageInYears = (
      year + (month < 10 && month > 3 ? 0.5 : month >= 10 ? 1 : 0)
    )
      .toFixed(1)
      .toString()

    const bmiJsonIAP = bmiJson?.[gender]?.['bmi']?.[ageInYears]

    let bmiStatus = ''

    if (BMI) {
      if (BMI < bmiJsonIAP['5']) {
        bmiStatus = 'Underweight'
      } else if (BMI >= bmiJsonIAP['5'] && BMI < bmiJsonIAP['85']) {
        bmiStatus = 'Healthy'
      } else if (BMI >= bmiJsonIAP['85'] && BMI < bmiJsonIAP['95']) {
        bmiStatus = 'Overweight'
      } else if (BMI >= bmiJsonIAP['95']) {
        bmiStatus = 'Obese'
      }

      return {
        bmi: +BMI,
        bmiStatus: bmiStatus,
      }
    }
  } else {
    return {
      bmi: '',
      bmiStatus: '',
    }
  }
}

function calculateAge(startDate) {
  const currentDate = new Date()
  const start = new Date(startDate)

  let totalMonths =
    (currentDate.getFullYear() - start.getFullYear()) * 12 +
    currentDate.getMonth() -
    start.getMonth()
  let years = Math.floor(totalMonths / 12)
  let months = totalMonths % 12

  return {
    year: years,
    month: months,
  }
}
