import styled from 'styled-components'

export const InputContainerWrapper = styled.div`
  position: relative;
  width: fit-content;
  .input-container__overlay-image-container {
    position: relative;
    padding: 10px;
  }
  .input-container__overlay-image {
    border-radius: 20px;
    width: 152px;
    height: 152px;
  }
  .input-container__cross-image {
    position: absolute;
    width: 16px;
    height: 16px;
    right: 18px;
    top: 16px;
  }
  .input-container__system-input {
    padding: 10px;
  }
  .input-container__input-tag {
    display: none;
  }
  .input-container__input-label {
    width: 152px;
    height: 152px;
  }
  @media screen and (min-width: 768px) {
    .input-container__cross-image {
      position: absolute;
      width: 30px;
      height: 30px;
      right: 25px;
      top: 25px;
    }
    .input-container__overlay-image {
      border-radius: 30px;
      width: 216px;
      height: 216px;
    }
    .input-container__input-label {
      width: 216px;
      height: 216px;
    }
  }
`
