import {HttpClient, useUserStore} from '@mosaic-wellness/redux-action-library'
import {useCallback, useEffect, useState} from 'react'
import {BACKEND_BASE_PATH} from 'src/utils/constants/breakPoints'
import {getLoggedOutDoctors} from './utils'
import {GenericCta, useGenericCta} from '../useGenericCta'
import {analyticsTrigger} from 'src/analytics'
import {EVENT_MAP} from 'src/analytics/eventMap'
import {useRouter} from 'next/router'
import {IDoctorCore} from 'src/Screens/DoctorList/types'

const useDoctorList = (props: {
  web: {
    loginCtaText: string
    viewProfile: string
  }
}) => {
  const axiosInstance = HttpClient.getAxiosInstance()

  const [doctors, setDoctors] = useState<IDoctorCore[]>([])
  const [loading, setLoading] = useState(true)

  const {
    user: {isLoggedIn},
  } = useUserStore()
  const {query} = useRouter()

  const {handleCta} = useGenericCta()

  const getData = useCallback(async () => {
    const querySuffix = query?.category ? `?category=${query.category}` : ''

    try {
      const response = await axiosInstance.get(
        `${BACKEND_BASE_PATH}/so/doctor-listing${querySuffix}`,
        {
          headers: {
            mwplatform: 'web',
          },
        }
      )

      if (isLoggedIn) {
        setDoctors(response.data.data.doctors)
      } else {
        setDoctors(
          getLoggedOutDoctors(
            response.data.data.doctors,
            isLoggedIn,
            props.web.loginCtaText,
            props.web.viewProfile
          )
        )
      }

      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }, [isLoggedIn])

  useEffect(() => {
    analyticsTrigger(EVENT_MAP.DOCTORS_VIEWED, {})
  }, [])

  useEffect(() => {
    getData()
  }, [getData])

  const onDoctorItemPress = (item: GenericCta) => (e: any) => {
    analyticsTrigger(EVENT_MAP.CTA_ACTION_CLICKED, {
      ...item,
    })

    handleCta(item)

    e.stopPropagation()
  }

  const onItemPress = (item: GenericCta) => () => {
    if (item) {
      analyticsTrigger(EVENT_MAP.CTA_ACTION_CLICKED, {
        ...item,
      })

      handleCta(item)
    }
  }

  const onCardPress = (item: any) => () => {
    handleCta({
      action: 'APP_NAV',
      link: `/doctor/${item.id}`,
    })
  }

  return {doctors, onDoctorItemPress, loading, onItemPress, onCardPress}
}

export {useDoctorList}
