import {useEffect} from 'react'
import {docCookies} from 'src/utils/cookie'

/**
 * ? WHY all of this?
 * we would want to attribute user interaction to the traffic source even if user abandons a session and,
 * returns to the site later with in a pre-specified time period - currently 7 Days
 */
export const useUTM = () => {
  useEffect(() => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const utmSource = urlParams.get('utm_source') || ''
    const utmMedium = urlParams.get('utm_medium') || ''
    const utmCampaign = urlParams.get('utm_campaign') || ''

    /**
     * * We need to check if any of the values are present.
     * ? WHY?
     * * even if one of the utm parameter being present implies the user landed through a different traffic source
     * and hence we need to update the other parameters as well even if they are empty
     */
    if (utmSource || utmMedium || utmCampaign) {
      docCookies.setItem({
        cookieName: 'utm_source',
        cookieValue: utmSource,
        expiry: 7 * 24 * 60 * 60,
      })
      docCookies.setItem({
        cookieName: 'umt_medium',
        cookieValue: utmMedium,
        expiry: 7 * 24 * 60 * 60,
      })
      docCookies.setItem({
        cookieName: 'utm_campaign',
        cookieValue: utmCampaign,
        expiry: 7 * 24 * 60 * 60,
      })
    }
  }, [])
}
