import {useCallback} from 'react'

import {useConfigStore} from '@mosaic-wellness/redux-action-library'
import {EVENT_MAP} from 'src/analytics/eventMap'

function useRescheduleScreenTracking() {
  const {analytics} = useConfigStore()
  const onRescheduleScreenViewed = useCallback(
    (data) => analytics.trigger(EVENT_MAP.RESCHEDULE_SCREEN_VIEWED, data),
    [analytics]
  )

  const onRescheduleLanguageChanged = useCallback(
    (data) => analytics.trigger(EVENT_MAP.RESCHEDULE_LANGUAGE_CHANGED, data),
    [analytics]
  )

  const onRescheduleScreenConfirmClicked = useCallback(
    (data) => analytics.trigger(EVENT_MAP.RESCHEDULE_CONFIRM_CLICKED, data),
    [analytics]
  )

  return {
    onRescheduleScreenViewed,
    onRescheduleLanguageChanged,
    onRescheduleScreenConfirmClicked,
  }
}

export default useRescheduleScreenTracking
