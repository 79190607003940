import React, {useCallback} from 'react'
import isEmpty from 'lodash/isEmpty'
import noop from 'lodash/noop'

import {InputContainerWrapper} from './InputContainer.styles'

const InputContainer = (props) => {
  const {
    label = '',
    uploadedImage = '',
    crossImage = '',
    key = 0,
    imageSizeExceededText = '',
  } = props.state || {}

  const {
    handleUploadImage = noop,
    handleImageClose = noop,
    onImageSizeExceeded = noop,
    toast = noop,
  } = props.actions || {}

  const handleInput = useCallback(
    (e) => {
      const file = e.target.files[0]
      const fileType = file.type

      if (file) {
        const fileSize = (file.size / 1024 / 1024).toFixed(4) //calculating image size in MB
        const allowedFileTypes = ['image/jpg', 'image/png', 'image/jpeg']
        if (!allowedFileTypes.includes(fileType)) {
          toast('warning', 'Only Image file Supported')
          return
        }

        if (fileSize <= 10) {
          handleUploadImage(file, key)
        } else {
          onImageSizeExceeded({
            message: imageSizeExceededText,
            size: fileSize,
          })
        }
      }
    },
    [handleUploadImage, key, onImageSizeExceeded, imageSizeExceededText]
  )

  const handleClose = useCallback(() => {
    handleImageClose(key)
  }, [handleImageClose, key])

  if (isEmpty(props.state)) {
    return null
  }

  return (
    <InputContainerWrapper key={key}>
      {uploadedImage ? (
        <div className="input-container__overlay-image-container">
          <img
            src={URL.createObjectURL(uploadedImage)}
            width="152px"
            height="152px"
            className="input-container__overlay-image"
            alt="selected preview"
          />
          <img
            src={crossImage}
            className="input-container__cross-image"
            onClick={handleClose}
            alt="selected preview"
          />
        </div>
      ) : (
        <div className="input-container__system-input">
          <input
            type="file"
            id="imageFile"
            name="imageFile"
            accept="image/*"
            className="input-container__input-tag"
            onChange={handleInput}
            onDrop={handleInput}
          />
          <label for="imageFile">
            <img
              src={label}
              className="input-container__input-label"
              onDrop={handleInput}
              alt="label for upload"
            />
          </label>
        </div>
      )}
    </InputContainerWrapper>
  )
}
export default InputContainer
